
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/left.png'
import logos from '../assets/logo.png'
import ReactiveButton from 'reactive-button';
import Select, { components } from 'react-select';
import '../App.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { db } from '../config';
import { decryptPass, encrypt, encryptPass } from '../Crypto';
import { toast } from 'wc-toast';
import { connect } from 'react-redux';
import { LogOut, MakeTrial, SetId, SetProject } from './redux/actions';
import { useLottie } from 'lottie-react';
import waiting from '../assets/Waiting.json'
import { useIdleTimer } from 'react-idle-timer';
import AnimateHeight from 'react-animate-height';
import moment from 'moment/moment';

const userdb = db.ref('userdb')
const coachdb = db.ref('coachdb')

const input_filed_color = '#e7f1f4'
const button_color = '#41A4C3'

const search_icon = <RiArrowDropDownLine color='#0b6d0f' size={24} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={24} />

const timeout = 10_000
const promptBeforeIdle = 4_000

function Login({ SetId, SetProject, LogOut, MakeTrial }) {

    const options = {
        animationData: waiting,
        loop: true,
        width: 800,
        height: 800
    };

    const { View } = useLottie(options);

    const DropdownIndicator = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon}
                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon_another}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const navigate = useNavigate()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    console.log('Width ', windowWidth)

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [mail, setMail] = useState('')

    const [pass, setPass] = useState('')
    const [secure, setSecure] = useState(true)
    const [loading, setLoading] = useState(false)

    function getRemain(id) {
        let res = moment(new Date()).diff(new Date(Number(id)), 'days')
        return 30 < res ? 0 : 30 - res;
    }

    async function check_exist() {
        setLoading(true)
        let check = await userdb.orderByChild('email').equalTo(encrypt(mail)).once('value')
        
        if (check.val() == null) {
            toast('This email does not exist')
            setLoading(false)
        }
        else {
            let arr = Object.values(check.val())

            console.log('pass ',decryptPass(arr[0].pass))

            if (arr[0].pass == undefined) {
                toast('You need to signup with your email')
            }

            else if (arr[0].pass == encryptPass(pass)) {
                
                await userdb.child(String(arr[0].ID)).update({ last: new Date().getTime() })
                SetId(arr[0])
                MakeTrial(getRemain(arr[0].ID))
                setLoading(false)
                LogOut(false)
                console.log(getRemain(arr[0].ID))
                if (getRemain(arr[0].ID) >0||arr[0].sub_id!=undefined) navigate('/Dashboard')
                else navigate('/Settings')
            }
            else {
                toast('Password is wrong')
                setLoading(false)
            }

        }
    }


    function hasLowerCase(str) {
        return str.toUpperCase() != str;
    }

    function hasUpperCase(str) {
        return str.toLowerCase() != str;
    }

    function ispass(pass) {
        let ret = 0;
        if (hasLowerCase(pass)) ret++;
        if (hasUpperCase(pass)) ret++;
        if (pass.length >= 6) ret++;
        if (pass.includes(0) || pass.includes(1) || pass.includes(2) || pass.includes(3) || pass.includes(4) || pass.includes(5) || pass.includes(6) || pass.includes(7) || pass.includes(8) || pass.includes(9)) ret++;

        return ret == 4

    }

    const [he, setHe] = useState(0)

    useEffect(() => {
        if (isMobile) setHe(windowHeight - (windowHeight / 2.5))
        else setHe(windowHeight)
    }, [windowHeight])

    const isMobile = windowWidth <= 768;

    const getHeight = Math.max(windowHeight, 1000)

    const mobileRef = useRef()

    useEffect(() => {
        mobileRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (!isMobile ? 
    <div style={{ display: 'flex', backgroundColor: '#1f2327', height: windowHeight, width: windowWidth, flexDirection: 'row' }}>

        <div style={{ width: 0.45 * windowWidth, height: windowHeight, display: 'block', justifyContent: 'center', backgroundColor: '#1f2327' }}>
            <img src={logo} style={{ width: 0.45 * windowWidth, height: windowHeight }} />
            <img src={logos} style={{ width: 330, height: 330, position: 'absolute', top: (0.50 * windowHeight - 165), left: (0.22 * windowWidth - 165) }} />
        </div>

        <AnimateHeight duration={900} height={he} style={{ width: 0.55 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: windowHeight, display: 'flex' }}>


            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'center', fontSize: 38, fontWeight: '700', fontFamily: 'Roboto', color: '#8455f6' }}>Welcome Back</text>
                    <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '700', fontFamily: 'Roboto', color: '#A0AEC0' }}>Enter your email and password to login</text>
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Email</text>
                    <input placeholder=' Your email address' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 380, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', marginLeft: 4 }}>
                <text style={{ width: 386, textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3', alignSelf: 'center' }}>Password</text>
                <div style={{ width: 386, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: '#292e33', borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', justifyContent: 'space-between', marginTop: 4 }}>
                    <input placeholder='Your password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ paddingLeft: 6, color: '#A0AEC0', fontFamily: 'Roboto', fontSize: 14, fontWeight: '400', backgroundColor: '#292e33', borderStyle: 'none', outline: 'none', height: 45, width: 350, marginLeft: 6, outline: 'none' }} />
                    <div style={{ width: 50 }}>
                        {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} />}
                    </div>
                </div>
                {!ispass(pass) ? <text style={{ fontFamily: 'Roboto', width: 380, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}>Password should contain lowecase , uppercase , numbers and at least 6 characters in length</text> : <text style={{ fontFamily: 'Montserrat', width: 400, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}></text>}
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'right', flexDirection: 'column' }}>
                    <text onClick={() => navigate('/Forget')} style={{ display: 'flex', justifyContent: 'right', color: '#a4b2c3', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', textDecorationLine: 'underline', textDecorationColor: '#a4b2c3', cursor: 'pointer', textAlign: 'right' }}>Forgot password ?</text>
                </div>
            </div>

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Login</text>}
                    successText="Done"
                    onClick={() => { check_exist() }}
                    style={{ backgroundColor: '#8455f6', width: 380, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 25 }}
                />
            </div>
            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <text style={{ fontFamily: 'Roboto', marginTop: 60, textAlign: 'center', fontSize: 14, fontWeight: '400', color: '#a4b2c3', display: 'flex' }}>Don't have an account ? <text onClick={() => navigate('/Signup')} style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: '700', marginLeft: 8, cursor: 'pointer', color: '#8455f6' }}>Sign Up</text></text>
                </div>
            </div>
        </AnimateHeight>

        {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
            {View}
        </div> : null}

    </div> : <div style={{ display: 'flex', backgroundColor: '#1f2327', height: Math.max(1000, windowHeight), width: windowWidth, flexDirection: 'column' }}>

        <div style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', justifyContent: 'center', backgroundColor: '#1f2327', alignSelf: 'center' }}>
            <img src={logo} style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', alignSelf: 'center' }} />
            <img ref={mobileRef} src={logos} style={{ width: 230, height: 230, position: 'absolute', top: ((getHeight / 2.5) / 2) - 115, }} />
        </div>

        <AnimateHeight duration={900} height={he} style={{ zIndex: 4000, width: 0.95 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: getHeight - (getHeight / 2.5), display: 'flex', alignSelf: 'center' }}>


            <div style={{ zIndex: 4000, width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 130, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'center', fontSize: 38, fontWeight: '700', fontFamily: 'Roboto', color: '#8455f6' }}>Welcome Back</text>
                    <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '700', fontFamily: 'Roboto', color: '#A0AEC0' }}>Enter your email and password to login</text>
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Email</text>
                    <input placeholder=' Your email address' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 300, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', marginLeft: 4 }}>
                <text style={{ width: 300, textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3', alignSelf: 'center' }}>Password</text>
                <div style={{ width: 307, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: '#292e33', borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', justifyContent: 'space-between', marginTop: 4 }}>
                    <input placeholder='Your password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ paddingLeft: 6, color: '#A0AEC0', fontFamily: 'Roboto', fontSize: 14, fontWeight: '400', backgroundColor: '#292e33', borderStyle: 'none', outline: 'none', height: 45, width: 300, marginLeft: 6, outline: 'none' }} />
                    <div style={{ width: 50 }}>
                        {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} />}
                    </div>
                </div>
                {!ispass(pass) ? <text style={{ fontFamily: 'Roboto', width: 300, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}>Password should contain lowecase , uppercase , numbers and at least 6 characters in length</text> : <text style={{ fontFamily: 'Montserrat', width: 300, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}></text>}
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'right', flexDirection: 'column' }}>
                    <text onClick={() => navigate('/Forget')} style={{ display: 'flex', justifyContent: 'right', color: '#a4b2c3', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', textDecorationLine: 'underline', textDecorationColor: '#a4b2c3', cursor: 'pointer', textAlign: 'right' }}>Forgot password ?</text>
                </div>
            </div>

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Login</text>}
                    successText="Done"
                    onClick={() => { check_exist() }}
                    style={{ backgroundColor: '#8455f6', width: 300, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 20, marginRight: 15 }}
                />
            </div>
            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <text style={{ fontFamily: 'Roboto', marginTop: 50, textAlign: 'center', fontSize: 14, fontWeight: '400', color: '#a4b2c3', display: 'flex' }}>Don't have an account ? <text onClick={() => navigate('/Signup')} style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: '700', marginLeft: 8, cursor: 'pointer', color: '#8455f6' }}>Sign Up</text></text>
                </div>
            </div>
            <div style={{ width: windowWidth, height: 90, marginTop: 20 }} />
        </AnimateHeight>

        {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
            {View}
        </div> : null}

    </div>)
}


const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId, SetProject, LogOut, MakeTrial })(Login)