
import './App.css';
import {
    BrowserRouter,
    Routes, //replaces "Switch" used till v5
    Route,
    HashRouter,
    useNavigate
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { auth } from './config';
//import { ToastContainer, toast } from 'react-toastify';
import { toast } from 'wc-toast'
import Login from './screens/Login';
import Signup from './screens/Signup';

import { Provider } from "react-redux";
import store from "./screens/redux/stores/index";
import Dashboard from './screens/Dashboard';
import Vision from './screens/Vision';
import Goals from './screens/Goals';
import WeeklyPlan from './screens/WeeklyPlan';
import Journal from './screens/Journal';
import ActivityPlan from './screens/ActivityPlan';
import Wins from './screens/Wins';
import Habit from './screens/Habit';
import Videos from './screens/Videos';
import Dashboardcompany from './company_screens/Dashboardcompany';
import CompanyAccount from './company_screens/CompanyAccount';
import Payment from './company_screens/Payment';
import TeamMembers from './company_screens/TeamMembers';
import LoginCompany from './screens/LoginCompany';
import SignUpCompany from './screens/SignUpCompany';
import Forget from './screens/Forget';
import ForgetCompany from './screens/ForgetCompany';
import { IdleTimerComponent, useIdleTimer } from 'react-idle-timer'
import App from './App';
//import 'react-toastify/dist/ReactToastify.css';

const timeout = 10_000
const promptBeforeIdle = 4_000

export default function Main() {

    const ___doSingIn = async () => {
        try {
            let response = await auth.signInWithEmailAndPassword("n.joy@boomsoftware.co.uk", "2103199j");
            if (response && response.user) {

            }
        } catch (e) {
            console.error(e.message);
        }
    };

    useEffect(() => {
        ___doSingIn()
    }, [])


    return (
        <div className="App">
            <Provider store={store}>
                <App />
            </Provider>
        </div>
    );
}