
import React, { useState, useEffect } from 'react';
import logo from '../assets/left.png'
import logos from '../assets/logo.png'
import ReactiveButton from 'reactive-button';
import Select, { components } from 'react-select';
import '../App.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { db } from '../config';
import { encrypt, encryptPass } from '../Crypto';
import { toast } from 'wc-toast';
import { connect } from 'react-redux';
import { SetId, SetProject } from './redux/actions';
import { useLottie } from 'lottie-react';
import waiting from '../assets/Waiting.json'

const userdb = db.ref('coachdb')
const coachdb = db.ref('coachdb')

const input_filed_color = '#e7f1f4'
const button_color = '#41A4C3'

const search_icon = <RiArrowDropDownLine color='#0b6d0f' size={24} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={24} />


function ForgetCompany({ SetId, SetProject }) {

    const options = {
        animationData: waiting,
        loop: true,
        width: 800,
        height: 800
    };

    const { View } = useLottie(options);

    const DropdownIndicator = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon}
                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon_another}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const navigate = useNavigate()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    console.log('Width ', windowWidth)

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [mail, setMail] = useState('')

    const [pass, setPass] = useState('')
    const [secure, setSecure] = useState(true)
    const [loading, setLoading] = useState(false)

    const [otp, setOtp] = useState(null)

    const [details,setDetails] = useState(null)

    async function check_exist() {

        if (otp) {
            if(otp==pass){
                SetId(details)
                setLoading(false)
                navigate('/Dashboardcompany')
            }
            else {
                toast('Your verification code is wrong')
            }
        }

        else {
            setLoading(true)
            let check = await userdb.orderByChild('email').equalTo(encrypt(mail)).once('value')

            if (check.val() == null) {
                toast('This email doesnot exist')
                setLoading(false)
            }
            else {
                let arr = Object.values(check.val())
                setDetails(arr[0])

                let res = await fetch(`https://us-central1-yescoach-5f1d9.cloudfunctions.net/app/mail_verification?email=${mail}`)
                res = await res.json()
                setOtp(res.otp)
                toast('A verification code has been sent to your email')
                console.log('Res ', res)
                // mail_verification
            }
        }
    }




    return (<div style={{ display: 'flex', backgroundColor: '#1f2327', height: windowHeight, width: windowWidth, flexDirection: 'row' }}>

        <div style={{ width: 0.45 * windowWidth, height: windowHeight, display: 'block', justifyContent: 'center', backgroundColor: '#1f2327' }}>
            <img src={logo} style={{ width: 0.45 * windowWidth, height: windowHeight }} />
            <img src={logos} style={{ width: 330, height: 330, position: 'absolute', top: (0.50 * windowHeight - 165), left: (0.22 * windowWidth - 165) }} />
        </div>

        <div style={{ width: 0.55 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: windowHeight, display: 'flex' }}>
            <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -100, flexDirection: 'column' }}>
                <text style={{ fontFamily: 'Roboto', color: '#8455f6', fontSize: 38, fontWeight: '700' }}>Welcome Back</text>
                <text style={{ fontFamily: 'Roboto', color: '#A0AEC0', fontSize: 14, fontWeight: '700' }}>Enter your email and verify your account</text>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Email</text>
                    <input placeholder=' Your email address' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 380, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                </div>
            </div>

            {otp ? <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', marginLeft: 6 }}>
                <text style={{ width: 386, textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3', alignSelf: 'center' }}>Password</text>
                <div style={{ width: 386, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: '#292e33', borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', justifyContent: 'space-between', marginTop: 4 }}>
                    <input placeholder='Your verification code' value={pass} onChange={(e) => setPass(e.currentTarget.value)} style={{ paddingLeft: 6, color: '#A0AEC0', fontFamily: 'Roboto', fontSize: 14, fontWeight: '400', backgroundColor: '#292e33', borderStyle: 'none', outline: 'none', height: 45, width: 350, marginLeft: 6, outline: 'none' }} />
                    <div style={{ width: 50 }}>
                        {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} />}
                    </div>
                </div>
            </div> : null}

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>{otp ? 'Verify' : 'Send verification code'}</text>}
                    successText="Done"
                    onClick={() => { check_exist() }}
                    style={{ backgroundColor: '#8455f6', width: 380, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 25 }}
                />
            </div>
            <text style={{ fontFamily: 'Roboto', marginTop: 50, textAlign: 'center', fontSize: 14, fontWeight: '400', color: '#a4b2c3' }}>Don't have an account ? <text onClick={() => navigate('/Signup')} style={{ fontFamily: 'Roboto', fontSize: 17, fontWeight: '700', marginLeft: 4, cursor: 'pointer', color: '#8455f6' }}>Sign Up</text></text>
        </div>

        {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
            {View}
        </div> : null}

    </div>)
}


const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId, SetProject })(ForgetCompany)