
export const SET_ID = 'SET_ID' ;
export const SET_PROJECT = 'SET_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_URI = 'ADD_URI';
export const SWITCH_MODE = 'SWITCH_MODE';
export const LOG_OUT = 'LOG_OUT';
export const MAN_FAVOURITE = 'MAN_FAVOURITE';
export const MAKE_TRIAL = 'MAKE_TRIAL';

export function SetId(payload){
    return {
        type:'SET_ID',
        payload
    }
}

export function SetProject(payload){
    return {
        type:'SET_PROJECT',
        payload
    }
}

export function AddProject(payload){
    return {
        type:'ADD_PROJECT',
        payload
    }
}

export function AddUri(payload){
    return {
        type:'ADD_URI',
        payload
    }
}

export function ManFav(payload){
    return {
        type:'MAN_FAVOURITE',
        payload
    }
}

export function SwitchMode(payload){
    return {
        type:'SWITCH_MODE',
        payload
    }
}

export function LogOut(payload){
    return {
        type:'LOG_OUT',
        payload
    }
}

export function MakeTrial(payload){
    return {
        type:'MAKE_TRIAL',
        payload
    }
}

