
import '../App.css';
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/logo.png'
import { useNavigate } from 'react-router-dom';
import { db, firestore } from '../config';
import moment from 'moment/moment';
import { FaUserFriends } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
//import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { toast } from 'wc-toast'
import { RxExternalLink } from "react-icons/rx";
import { active_activityplan_icon, active_goal_icon, active_journal_icon, active_vision_icon, active_week_plan_icon, activities_dashboard_icon, inactive_activityplan_icon, inactive_dashboard_icon, inactive_goal_icon, inactive_habit_icon, inactive_journal_icon, inactive_video_icon, inactive_vision_icon, inactive_week_plan_icon, inactive_win_icon, logout_icon, pay_active, team_inactive, user_inactive } from '../assets/Icon';
import ReactiveButton from 'reactive-button';
import { RxCross1 } from "react-icons/rx";
import { connect } from 'react-redux';
import { SetId } from '../screens/redux/actions';
import { decrypt } from '../Crypto';
import { Database } from 'firebase/database';
import ToggleButton from 'react-toggle-button'
import { LuChevronDown } from "react-icons/lu";
import Popup from 'reactjs-popup';
import { VscThreeBars } from 'react-icons/vsc';
import Modal from 'react-responsive-modal';
import axios from 'axios';

//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const inactive_color = '#cbb6fe'

const out_link = <RxExternalLink size={24} color='black' />

const partnerdb = db.ref('partnerdb');
const customerdb = db.ref('customerdb');

const candidate_db = firestore.collection('candidate_db')

const recruiter_db = db.ref('recruiter_db');


const recruiter_active = <FaUserTie color='white' size={22} />
const recruiter_active_non = <FaUserTie color='#B9B9B9' size={22} />


function Payment({ items, SetId }) {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(Math.max(window.innerHeight, 1100));

    useEffect(() => {
        const windowSizeHandler = () => {
            setWidth(window.innerWidth)
            setHeight(Math.max(window.innerHeight, 1100))
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);


    let curr = new Date().getTime();

    const navigate = useNavigate();

    const [touched1, setTouched1] = useState(false);
    const [password_pop_up, setPasswordPopUp] = useState(true);

    const [my_open_goals, setMyOpenGoals] = useState([])


    const [emotion, setEmotion] = useState(false)

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']


    const isMobile = width <= 768;

    const getHeight = Math.max(height, 1300)

    const setting_ref = useRef()
    const top_ref = useRef()
    const [price, setPrice] = useState(0)
    const [user, setUser] = useState(0)

    useEffect(() => {
        const company_payment_db = db.ref('company_payment');
        company_payment_db.child(String(items.myid.package_id)).once('value').then((snapshot) => {
            if (snapshot.val() != null) {
                let arr = snapshot.val()
                setPrice(arr.pay)
                setUser(arr.user)
            }
        })
    }, [])

    const [delete_pop_up, setDeletePopUp] = useState(false)

    async function deleteUser() {
        let sub_id = items.myid.sub_id;
        const coachdb = db.ref('coachdb')
        await axios.post('https://us-central1-yescoach-5f1d9.cloudfunctions.net/app/cancel', { 'sub_id': sub_id });
        await coachdb.child(String(items.myid.ID)).update({ sub_id: null, package_id: null })
        setDeletePopUp(false)
        navigate('/company_login')
    }

    return (
        !isMobile ? <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0 }}>
            <div style={{ backgroundColor: '#8455f6', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', height: height, width: 286, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                </div>

                <div onClick={() => navigate('/Dashboardcompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                    </div>
                </div>

                <div onClick={() => navigate('/TeamMembers')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{team_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                    </div>
                </div>

                <div style={{ height: 48, marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{pay_active}</div>
                        <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                    </div>
                </div>

                <div onClick={() => navigate('/CompanyAccount')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                    </div>
                </div>

                <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                    </div>
                </div>

                <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 286, width: width - 286, position: 'absolute', top: 0, height: height, backgroundColor: '#1f2327' }}>
                <div style={{ display: 'flex', height: 100, width: width - 286, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <text></text>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>PAYMENT</text>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 0.4 * width, justifyContent: 'flex-start', height: 836, backgroundColor: '#292E33', alignSelf: 'center', marginTop: 10 }}>


                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.2980 * width, justifyContent: 'flex-start', height: 'auto', alignSelf: 'center', marginTop: 40 }}>

                        <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0 }}>Monthly payment</text>
                        <div style={{ display: 'flex', alignSelf: 'center' }}>

                            <text style={{ textAlign: 'center', fontSize: 34, fontWeight: '400', fontFamily: 'Roboto', color: '#8455F6', marginTop: 0, alignSelf: 'flex-end', marginBottom: 7 }}>£ </text>
                            <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: '#8455F6', marginTop: 0, alignSelf: 'flex-start' }}>{price}</text>


                        </div>

                        <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0 }}>Maximum users {user}</text>


                        <div style={{ display: 'flex', width: 0.2980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#D7EAFF', marginTop: 5 }}>First name</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>

                            <input placeholder='joson_statham' value={`${decrypt(items.myid.first)}`} style={{ alignSelf: 'center', fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#1F2327', width: 0.2980 * width, height: 43, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                        </div>

                        <div style={{ display: 'flex', width: 0.2980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#D7EAFF', marginTop: 5 }}>Last name</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>

                            <input value={`${decrypt(items.myid.second)}`} placeholder='Name' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#1F2327', width: 0.2980 * width, height: 43, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                        </div>

                        <div style={{ display: 'flex', width: 0.2980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#D7EAFF', marginTop: 5 }}>Email</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>{`${decrypt(items.myid.email)}`}</text>

                        </div>


                        <div onClick={() => navigate('/Packages')} style={{ cursor: 'pointer', display: 'flex', backgroundColor: '#8455F6', height: 43, width: 300, borderRadius: 4, marginTop: 40, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF' }}>Change Plan</text>
                        </div>

                        <div onClick={() => setDeletePopUp(true)} style={{ cursor: 'pointer', display: 'flex', backgroundColor: '#FF4747', height: 43, width: 300, borderRadius: 4, marginTop: 10, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF' }}>Cancel Plan</text>
                        </div>

                    </div>

                </div>

            </div>

            <Modal open={!isMobile && delete_pop_up != false}
                onClose={() => setDeletePopUp(false)}
                center
                styles={{
                    modal: {
                        backgroundColor: '#1F2327',
                    },
                    closeButton: { backgroundColor: 'white', borderRadius: 15 }
                }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'white' }}>  Are you sure you want to cancel plan ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={() => deleteUser()} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div>

            </Modal>

            <wc-toast></wc-toast>

        </div>
            : <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'column', width: width, height: getHeight, position: 'absolute', top: 0, left: 0 }}>


                <div style={{ display: 'flex', flexDirection: 'column', width: width, height: getHeight, backgroundColor: '#1f2327' }}>
                    <div style={{ display: 'flex', height: 150, width: width, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                        <Popup
                            trigger={<div className="menu-item"><VscThreeBars color='white' size={35} style={{ marginLeft: 25 }} /></div>}
                            position="right top"
                            on="click"
                            closeOnDocumentClick={false}
                            mouseLeaveDelay={300}
                            mouseEnterDelay={30}
                            contentStyle={{ padding: '0px', border: 'none', marginRight: 50, width: 280, display: 'flex', borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                            arrow={true}
                            overlayStyle={{ marginLeft: -20 }}

                        >
                            <div style={{ backgroundColor: '#8455f6', display: 'flex', flexDirection: 'column', height: 580, width: 280, textAlign: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                                    <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                                </div>

                                <div onClick={() => navigate('/Dashboardcompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                                    <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                        <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                                    </div>
                                </div>

                                <div onClick={() => navigate('/TeamMembers')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                        <div style={{ color: 'white', fontSize: 23 }}>{team_inactive}</div>
                                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                                    </div>
                                </div>

                                <div style={{ height: 48, marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                    <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                        <div style={{ color: 'white', fontSize: 19 }}>{pay_active}</div>
                                        <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                                    </div>
                                </div>

                                <div onClick={() => navigate('/CompanyAccount')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                        <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                                    </div>
                                </div>

                                <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                        <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                                    </div>
                                </div>

                                <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />


                            </div>
                        </Popup>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                            <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>PAYMENT</text>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.96 * width, justifyContent: 'flex-start', height: getHeight - 200, backgroundColor: '#292E33', alignSelf: 'center', marginTop: 50 }}>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.8980 * width, justifyContent: 'flex-start', height: 'auto', alignSelf: 'center', marginTop: 40 }}>

                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0 }}>Monthly payment</text>
                            <div style={{ display: 'flex', alignSelf: 'center' }}>

                                <text style={{ textAlign: 'center', fontSize: 34, fontWeight: '400', fontFamily: 'Roboto', color: '#8455F6', marginTop: 0, alignSelf: 'flex-end', marginBottom: 7 }}>£ </text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: '#8455F6', marginTop: 0, alignSelf: 'flex-start' }}> {price}</text>

                            </div>

                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0 }}>Maximum users {user}</text>


                            <div style={{ display: 'flex', width: 0.8980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#D7EAFF', marginTop: 5 }}>First name</text>

                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.8980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>

                                <input value={decrypt(items.myid.first)} placeholder='joson_statham' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#1F2327', width: 0.2980 * width, height: 43, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                            </div>

                            <div style={{ display: 'flex', width: 0.8980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#D7EAFF', marginTop: 5 }}>Last name</text>

                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.8980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>

                                <input value={decrypt(items.myid.second)} placeholder='Name' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#1F2327', width: 0.2980 * width, height: 43, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 8 }} />


                            </div>

                            <div style={{ display: 'flex', width: 0.8980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#D7EAFF', marginTop: 5 }}>Email</text>

                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.8980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>
                                <text value={decrypt(items.myid.email)} style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Jason Statham</text>


                            </div>


                            <div onClick={() => navigate('/Packages')} style={{ cursor: 'pointer', display: 'flex', backgroundColor: '#8455F6', height: 43, width: 300, borderRadius: 4, marginTop: 40, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                                <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF' }}>Change Plan</text>
                            </div>

                            <div onClick={() => setDeletePopUp(true)} style={{ cursor: 'pointer', display: 'flex', backgroundColor: '#FF4747', height: 43, width: 300, borderRadius: 4, marginTop: 10, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                                <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF' }}>Cancel Plan</text>
                            </div>

                        </div>

                    </div>

                </div>

                <Modal open={isMobile && delete_pop_up != false}
                    onClose={() => setDeletePopUp(false)}
                    center
                    styles={{
                        modal: {
                            backgroundColor: '#1F2327',
                        },
                        closeButton: { backgroundColor: 'white', borderRadius: 15 }
                    }}
                >
                    <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 300, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
                            <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'white' }}>  Are you sure you want to cancel plan ?</text>
                        </div>
                        <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                            <div onClick={() => deleteUser()} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                            </div>

                            <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                            </div>

                        </div>
                    </div>

                </Modal>


                <wc-toast></wc-toast>

            </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId })(Payment)
