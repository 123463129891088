
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/left.png'
import logos from '../assets/logo.png'
import ReactiveButton from 'reactive-button';
import Select, { components } from 'react-select';
import '../App.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { db } from '../config';
import { decrypt, encrypt, encryptPass } from '../Crypto';
import { toast } from 'wc-toast';
import { connect } from 'react-redux';
import { SetId, SetProject, LogOut, MakeTrial } from '../screens/redux/actions';
import { useLottie } from 'lottie-react';
import waiting from '../assets/Waiting.json'
import { useIdleTimer } from 'react-idle-timer';
import AnimateHeight from 'react-animate-height';
import moment from 'moment/moment';
import axios from 'axios';
import { RiseLoader } from 'react-spinners';

const userdb = db.ref('userdb')
const coachdb = db.ref('coachdb')

const input_filed_color = '#e7f1f4'
const button_color = '#41A4C3'

const search_icon = <RiArrowDropDownLine color='#0b6d0f' size={24} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={24} />

const timeout = 10_000
const promptBeforeIdle = 4_000

function Packages({ SetId, SetProject, LogOut, MakeTrial, items }) {

    const options = {
        animationData: waiting,
        loop: true,
        width: 800,
        height: 800
    };

    const { View } = useLottie(options);

    const DropdownIndicator = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon}
                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon_another}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const navigate = useNavigate()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    console.log('Width ', windowWidth)

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [mail, setMail] = useState('')

    const [pass, setPass] = useState('')
    const [secure, setSecure] = useState(true)
    const [loading, setLoading] = useState(false)

    function getRemain(id) {
        let res = moment(new Date()).diff(new Date(Number(id)), 'days')
        return 30 < res ? 0 : 30 - res;
    }


    function hasLowerCase(str) {
        return str.toUpperCase() != str;
    }

    function hasUpperCase(str) {
        return str.toLowerCase() != str;
    }

    function ispass(pass) {
        let ret = 0;
        if (hasLowerCase(pass)) ret++;
        if (hasUpperCase(pass)) ret++;
        if (pass.length >= 6) ret++;
        if (pass.includes(0) || pass.includes(1) || pass.includes(2) || pass.includes(3) || pass.includes(4) || pass.includes(5) || pass.includes(6) || pass.includes(7) || pass.includes(8) || pass.includes(9)) ret++;

        return ret == 4

    }

    const [he, setHe] = useState(0)

    useEffect(() => {
        if (isMobile) setHe(windowHeight - (windowHeight / 2.5))
        else setHe(windowHeight)
    }, [windowHeight])

    const isMobile = windowWidth <= 768;

    const getHeight = Math.max(windowHeight, 1000)

    const mobileRef = useRef()

    useEffect(() => {
        mobileRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    const [packag, setPackag] = useState([])

    useEffect(() => {
        const company_payment_db = db.ref('company_payment');
        company_payment_db.once('value').then((snapshot) => {
            if (snapshot.val() != null) {
                let arr = Object.values(snapshot.val());
                arr.sort(function (a, b) {
                    return a.id - b.id
                })
                setPackag([...arr]);
            }
        })
    }, [])

    function select_any(id) {
        let temp = [...packag];
        for (let i = 0; i < packag.length; i++) temp[i].select = false
        temp[id].select = true;
        setPackag([...temp])
    }

    async function getSecret(price, mail, id) {
        toast('Please wait')
        if (items.myid.sub_id != null) {
            const res = await axios.post('https://us-central1-yescoach-5f1d9.cloudfunctions.net/app/cancel', { 'sub_id': items.myid.sub_id });
            console.log('Cancel ', res)
        }
        const res = await axios.post('https://us-central1-yescoach-5f1d9.cloudfunctions.net/app/sub', { 'email': mail, 'price': price });
        const { client_secret, status, sub_id, cust_id } = res.data;
        console.log(client_secret, status, sub_id, cust_id)
        setLoading(false)
        navigate('/StripePaymentCompany', { state: { clientSecret: client_secret, sub_id: sub_id, packageId: id, price: price } })
    }

    async function next_go() {
        setLoading(true)
        let id = 0;
        for (let i = 0; i < packag.length; i++) {
            if (packag[i].select) {
                id = packag[i].id
                break;
            }
        }
        let price = packag[id].pay;
        let mail = decrypt(items.myid.email)

        await getSecret(price, mail, id)
    }

    function ifExist() {
        for (let i = 0; i < packag.length; i++) {
            if (packag[i].select != undefined && packag[i].select) {
                return true;
            }
        }
        return false
    }

    return (!isMobile ?
        <div style={{ display: 'flex', backgroundColor: '#1f2327', height: windowHeight, width: windowWidth, flexDirection: 'row' }}>

            <div style={{ width: 0.45 * windowWidth, height: windowHeight, display: 'block', justifyContent: 'center', backgroundColor: '#1f2327' }}>
                <img src={logo} style={{ width: 0.45 * windowWidth, height: windowHeight }} />
                <img src={logos} style={{ width: 330, height: 330, position: 'absolute', top: (0.50 * windowHeight - 165), left: (0.22 * windowWidth - 165) }} />
            </div>

            <AnimateHeight duration={900} height={he} style={{ width: 0.55 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: windowHeight, display: 'flex' }}>


                <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                        <text style={{ textAlign: 'center', fontSize: 38, fontWeight: '700', fontFamily: 'Roboto', color: '#8455f6' }}>Yes Coach</text>
                        <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '700', fontFamily: 'Roboto', color: '#A0AEC0' }}>Choose your package</text>
                    </div>
                </div>

                {packag[0] != undefined && <div style={{ cursor: 'pointer', width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div onClick={() => select_any(0)} style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column', backgroundColor: '#292e33', padding: 15, borderRadius: 6, borderWidth: packag[0].select ? 1 : 0, borderColor: '#8455f6', borderStyle: 'solid' }}>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#8455f6' }}>1-{packag[0].user} Users</text>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>£{packag[0].pay} per month</text>
                    </div>
                </div>}

                {packag[1] != undefined && packag[0] != undefined &&
                    <div style={{ cursor: 'pointer', width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                        <div onClick={() => select_any(1)} style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column', backgroundColor: '#292e33', padding: 15, borderRadius: 6, borderWidth: packag[1].select ? 1 : 0, borderColor: '#8455f6', borderStyle: 'solid' }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#8455f6' }}>{Number(packag[0].user) + 1} - {Number(packag[1].user)} Users</text>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>£{packag[1].pay} per month</text>
                        </div>
                    </div>}

                {packag[1] != undefined && packag[0] != undefined && packag[2] != undefined && <div style={{ cursor: 'pointer', width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div onClick={() => select_any(2)} style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column', backgroundColor: '#292e33', padding: 15, borderRadius: 6, borderWidth: packag[2].select ? 1 : 0, borderColor: '#8455f6', borderStyle: 'solid' }}>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#8455f6' }}>{Number(packag[1].user)} + Users</text>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>£{packag[2].pay} per month</text>
                    </div>
                </div>}

                {loading ? <div style={{ cursor: 'pointer', width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 380, height: 60 }}>
                        <RiseLoader color={'#36D7B7'} isLoading={true}
                            css={override} size={20} />
                    </div></div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                </div>}

                <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}>
                    <ReactiveButton
                        disabled={!ifExist()}
                        idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Continue</text>}
                        successText="Done"
                        onClick={() => { next_go() }}
                        style={{ backgroundColor: '#8455f6', width: 380, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 25 }}
                    />
                </div>

            </AnimateHeight>

            {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
                {View}
            </div> : null}

        </div> : <div style={{ display: 'flex', backgroundColor: '#1f2327', height: Math.max(1000, windowHeight), width: windowWidth, flexDirection: 'column' }}>

            <div style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', justifyContent: 'center', backgroundColor: '#1f2327', alignSelf: 'center' }}>
                <img src={logo} style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', alignSelf: 'center' }} />
                <img ref={mobileRef} src={logos} style={{ width: 230, height: 230, position: 'absolute', top: ((getHeight / 2.5) / 2) - 115, }} />
            </div>

            <AnimateHeight duration={900} height={he} style={{ zIndex: 4000, width: 0.95 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: getHeight - (getHeight / 2.5), display: 'flex', alignSelf: 'center' }}>


                <div style={{ zIndex: 4000, width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 130, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                        <text style={{ textAlign: 'center', fontSize: 38, fontWeight: '700', fontFamily: 'Roboto', color: '#8455f6' }}>Yes Coach</text>
                        <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '700', fontFamily: 'Roboto', color: '#A0AEC0' }}>Choose your package</text>
                    </div>
                </div>


                {packag[0] != undefined && <div style={{ cursor: 'pointer', width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div onClick={() => select_any(0)} style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column', backgroundColor: '#292e33', padding: 15, borderRadius: 6, borderWidth: packag[0].select ? 1 : 0, borderColor: '#8455f6', borderStyle: 'solid' }}>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#8455f6' }}>1-{packag[0].user} Users</text>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>£{packag[0].pay} per month</text>
                    </div>
                </div>}

                {packag[1] != undefined && packag[0] != undefined &&
                    <div style={{ cursor: 'pointer', width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                        <div onClick={() => select_any(1)} style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column', backgroundColor: '#292e33', padding: 15, borderRadius: 6, borderWidth: packag[1].select ? 1 : 0, borderColor: '#8455f6', borderStyle: 'solid' }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#8455f6' }}>{Number(packag[0].user) + 1} - {Number(packag[1].user)} Users</text>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>£{packag[1].pay} per month</text>
                        </div>
                    </div>}

                {packag[1] != undefined && packag[0] != undefined && packag[2] != undefined && <div style={{ cursor: 'pointer', width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div onClick={() => select_any(2)} style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column', backgroundColor: '#292e33', padding: 15, borderRadius: 6, borderWidth: packag[2].select ? 1 : 0, borderColor: '#8455f6', borderStyle: 'solid' }}>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#8455f6' }}>{Number(packag[1].user)} + Users</text>
                        <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>£{packag[2].pay} per month</text>
                    </div>
                </div>}

                {loading ? <div style={{ cursor: 'pointer', width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 300, height: 60 }}>
                        <RiseLoader color={'#36D7B7'} isLoading={true}
                            css={override} size={20} />
                    </div></div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

                </div>}

                <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                    <ReactiveButton
                        disabled={!ifExist()}
                        idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Continue</text>}
                        successText="Done"
                        onClick={() => { next_go() }}
                        style={{ backgroundColor: '#8455f6', width: 300, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 20, marginRight: 15 }}
                    />
                </div>
                <div style={{ width: windowWidth, height: 90, marginTop: 20 }} />
            </AnimateHeight>

            {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
                {View}
            </div> : null}

        </div>)
}


const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

const override = `
display: block;
margin: 0 auto;
border-color: red;
alignSelf:center
`;

export default connect(mapStateToProps, { SetId, SetProject, LogOut, MakeTrial })(Packages)