

// Import the functions you need from the SDKs you need

import firebase from 'firebase/compat/app';
//import firebase from "firebase";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore"
import "firebase/compat/messaging"
import "firebase/compat/app-check"
//import "firebase/compat/messaging"

const config = {
  apiKey: "AIzaSyCjyBEVTmm8XOeFeXJPxiviILoFvonyCbg",
  authDomain: "yescoach-5f1d9.firebaseapp.com",
  databaseURL: "https://yescoach-5f1d9-default-rtdb.firebaseio.com",
  projectId: "yescoach-5f1d9",
  storageBucket: "yescoach-5f1d9.appspot.com",
  messagingSenderId: "625294866071",
  appId: "1:625294866071:web:08d41024db7b47052e5bd2",
  measurementId: "G-HMWE200K6T"
};

firebase.initializeApp(config);
// const db=firebase.database();
export const db = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebase.firestore();

//export const auth = firebase.auth();
//export const firestore = firebase.firestore()
//export const firebases = firebase;