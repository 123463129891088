
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/left.png'
import logos from '../assets/logo.png'
import ReactiveButton from 'reactive-button';
import Select, { components } from 'react-select';
import '../App.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../config';
import { encrypt, encryptPass } from '../Crypto';
import { toast } from 'wc-toast';
import { connect } from 'react-redux';

import { useLottie } from 'lottie-react';
import waiting from '../assets/Waiting.json'
import { useIdleTimer } from 'react-idle-timer';
import AnimateHeight from 'react-animate-height';
import moment from 'moment/moment';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutFormCompany from './CheckoutFormCompany';
import { SetId, SetProject, LogOut, MakeTrial } from '../screens/redux/actions';

const userdb = db.ref('userdb')
const coachdb = db.ref('coachdb')

const input_filed_color = '#e7f1f4'
const button_color = '#41A4C3'

const search_icon = <RiArrowDropDownLine color='#0b6d0f' size={24} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={24} />

const timeout = 10_000
const promptBeforeIdle = 4_000

function StripePaymentCompany({ SetId, SetProject, LogOut, MakeTrial, items }) {

    const state = useLocation()
    const navigate = useNavigate()

    const clientSecret = state.state.clientSecret
    const sub_id = state.state.sub_id
    const packageId = state.state.packageId
    const price = state.state.price

    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        setStripePromise(loadStripe('pk_live_51OuejF1rL2RNiczYAGLoXgnoX8q3j1c7zvznDIPgdi86GNAgcJnwDYt5hwGzyfPqT85CPZ514hbPbWordABTIbiF00o6S8FDEj'));
    }, []);


    const [success, setSuccess] = useState(null)

    function make_success(val) {
        setSuccess(val)
    }

    useEffect(() => {
        if (success) {
            console.log('Payment successful ')
            async function updating() {
                toast('Your payment is successful')
                await coachdb.child(String(items.myid.ID)).update({ sub_id: sub_id, package_id: packageId })
                let obj = items.myid;
                obj.sub_id = sub_id;
                obj.package_id = packageId;
                SetId(obj)
                navigate('/Dashboardcompany')
            }
            updating()
        }
    }, [success])

    const options = {
        animationData: waiting,
        loop: true,
        width: 800,
        height: 800
    };

    const { View } = useLottie(options);

    const DropdownIndicator = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon}
                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon_another}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(Math.max(900,window.innerHeight));

    console.log('Width ', windowWidth)

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(Math.max(900,window.innerHeight))
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);

    const [mail, setMail] = useState('')

    const [pass, setPass] = useState('')
    const [secure, setSecure] = useState(true)
    const [loading, setLoading] = useState(false)

    function getRemain(id) {
        let res = moment(new Date()).diff(new Date(Number(id)), 'days')
        return 30 < res ? 0 : 30 - res;
    }



    function hasLowerCase(str) {
        return str.toUpperCase() != str;
    }

    function hasUpperCase(str) {
        return str.toLowerCase() != str;
    }

    function ispass(pass) {
        let ret = 0;
        if (hasLowerCase(pass)) ret++;
        if (hasUpperCase(pass)) ret++;
        if (pass.length >= 6) ret++;
        if (pass.includes(0) || pass.includes(1) || pass.includes(2) || pass.includes(3) || pass.includes(4) || pass.includes(5) || pass.includes(6) || pass.includes(7) || pass.includes(8) || pass.includes(9)) ret++;

        return ret == 4

    }

    const [he, setHe] = useState(0)

    useEffect(() => {
        if (isMobile) setHe(windowHeight - (windowHeight / 2.5))
        else setHe(windowHeight)
    }, [windowHeight])

    const isMobile = windowWidth <= 768;

    const getHeight = Math.max(windowHeight, 1000)

    const mobileRef = useRef()

    useEffect(() => {
        mobileRef.current?.scrollIntoView({ behavior: 'smooth' })

    }, [])

    return (!isMobile ?
        <div style={{ display: 'flex', backgroundColor: '#1f2327', height: windowHeight, width: windowWidth, flexDirection: 'row' }}>

            <div style={{ width: 0.45 * windowWidth, height: windowHeight, display: 'block', justifyContent: 'center', backgroundColor: '#1f2327' }}>
                <img src={logo} style={{ width: 0.45 * windowWidth, height: windowHeight }} />
                <img src={logos} style={{ width: 330, height: 330, position: 'absolute', top: (0.50 * windowHeight - 165), left: (0.22 * windowWidth - 165) }} />
            </div>

            <AnimateHeight duration={900} height={he} style={{ width: 0.55 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: windowHeight, display: 'flex' }}>

                {stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{
                        clientSecret, appearance: {
                            theme: 'night',
                            labels: 'floating',
                            variables: {
                                colorPrimary: 'black',
                                colorBackground: '#f6fafc',
                                colorText: '#30313d',
                                borderRadius: '0px',
                            },
                            rules: {
                                '.Tab': {
                                    border: '0px solid #FFFFFF',
                                    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.03), 0px 0px 0px #FFFFFF',
                                    outline: 'none'
                                },

                                '.Tab:hover': {
                                    color: 'var(--colorText)',
                                },

                                '.Tab--selected': {
                                    borderColor: '#E0E6EB',
                                    border: 'none',
                                    outline: 'none'
                                },

                                '.Input': {
                                    boxShadow: 'inset 0 0 px rgb(209,213,219)',
                                    outline: 'none',
                                    padding: '0.75em 1em',
                                    color: 'black'
                                },

                                '.Input:focus': {
                                    boxShadow: 'inset 0 0 px rgb(209,213,219)',

                                    outline: 'none',
                                    padding: '0.75em 1em',
                                    color: 'black'
                                },

                                '.Input--invalid': {
                                    boxShadow: '0 0px 0px 0 #FFFFFF, 0 0 0 0px var(--colorDanger)',
                                    outline: 'none'
                                },

                                // See all supported class names and selector syntax below
                            }
                        },
                    }}>
                        <CheckoutFormCompany price={price} done={make_success} />
                    </Elements>
                )}
            </AnimateHeight>

            {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
                {View}
            </div> : null}

        </div> : <div style={{ display: 'flex', backgroundColor: '#1f2327', height: Math.max(1000, windowHeight), width: windowWidth, flexDirection: 'column' }}>

            <div style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', justifyContent: 'center', backgroundColor: '#1f2327', alignSelf: 'center' }}>
                <img src={logo} style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', alignSelf: 'center' }} />
                <img ref={mobileRef} src={logos} style={{ width: 230, height: 230, position: 'absolute', top: ((getHeight / 2.5) / 2) - 115, }} />
            </div>

            <AnimateHeight duration={900} height={he+200} style={{ zIndex: 4000, width: 0.95 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: getHeight - (getHeight / 2.5), display: 'flex', alignSelf: 'center' }}>

                {stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{
                        clientSecret, appearance: {
                            theme: 'night',
                            labels: 'floating',
                            variables: {
                                colorPrimary: 'black',
                                colorBackground: '#f6fafc',
                                colorText: '#30313d',
                                borderRadius: '0px',
                            },
                            rules: {
                                '.Tab': {
                                    border: '0px solid #FFFFFF',
                                    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.03), 0px 0px 0px #FFFFFF',
                                    outline: 'none'
                                },

                                '.Tab:hover': {
                                    color: 'var(--colorText)',
                                },

                                '.Tab--selected': {
                                    borderColor: '#E0E6EB',
                                    border: 'none',
                                    outline: 'none'
                                },

                                '.Input': {
                                    boxShadow: 'inset 0 0 px rgb(209,213,219)',
                                    outline: 'none',
                                    padding: '0.75em 1em',
                                    color: 'black'
                                },

                                '.Input:focus': {
                                    boxShadow: 'inset 0 0 px rgb(209,213,219)',

                                    outline: 'none',
                                    padding: '0.75em 1em',
                                    color: 'black'
                                },

                                '.Input--invalid': {
                                    boxShadow: '0 0px 0px 0 #FFFFFF, 0 0 0 0px var(--colorDanger)',
                                    outline: 'none'
                                },

                                // See all supported class names and selector syntax below
                            }
                        },
                    }}>
                        <CheckoutFormCompany price={price} done={make_success} />
                    </Elements>
                )}
            </AnimateHeight>

            {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
                {View}
            </div> : null}

        </div>)
}


const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}


export default connect(mapStateToProps, { SetId, SetProject, LogOut, MakeTrial })(StripePaymentCompany)