
import '../App.css';
import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png'
import { useNavigate } from 'react-router-dom';
import { db, firestore } from '../config';
import moment from 'moment/moment';
import { FaUserFriends } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
//import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { toast } from 'wc-toast'
import { RxExternalLink } from "react-icons/rx";
import { active_activityplan_icon, active_goal_icon, active_journal_icon, active_vision_icon, active_week_plan_icon, activities_dashboard_icon, inactive_activityplan_icon, inactive_dashboard_icon, inactive_goal_icon, inactive_habit_icon, inactive_journal_icon, inactive_video_icon, inactive_vision_icon, inactive_week_plan_icon, inactive_win_icon, logout_icon, pay_inactive, team_active, user_inactive } from '../assets/Icon';
import ReactiveButton from 'reactive-button';
import { RxCross1 } from "react-icons/rx";
import { connect } from 'react-redux';
import { SetId } from '../screens/redux/actions';
import { decrypt, encrypt } from '../Crypto';
import { Database } from 'firebase/database';
import ToggleButton from 'react-toggle-button'

import { RiDeleteBinFill } from "react-icons/ri";
import { FaPenToSquare } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import FlatList from 'flatlist-react';

import { FaArrowLeft } from "react-icons/fa";
import Popup from 'reactjs-popup';
import { VscThreeBars } from 'react-icons/vsc';
import Modal from 'react-responsive-modal';
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const inactive_color = '#cbb6fe'

const out_link = <RxExternalLink size={24} color='black' />

const userdb = db.ref('userdb');
const coachdb = db.ref('coachdb');

const candidate_db = firestore.collection('candidate_db')

const recruiter_db = db.ref('recruiter_db');


const recruiter_active = <FaUserTie color='white' size={22} />
const recruiter_active_non = <FaUserTie color='#B9B9B9' size={22} />


function TeamMembers({ items, SetId }) {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(Math.max(window.innerHeight, 1000));

    useEffect(() => {
        const windowSizeHandler = () => {
            setWidth(window.innerWidth)
            setHeight(Math.max(window.innerHeight, 1000))
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);


    let curr = new Date().getTime();

    const navigate = useNavigate();

    const [touched1, setTouched1] = useState(false);
    const [delete_pop_up, setDeletePopUp] = useState(false);

    const [my_users, setMygoals] = useState([])

    function ifemailexist(mail) {
        for (let i = 0; i < my_users.length; i++) {
            if (my_users[i].email == encrypt(mail)) return true;
        }
        return false;
    }

    useEffect(() => {
        let subscribe = coachdb.child(String(items.myid.ID)).child('myusers').on('value', async (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val());
                console.log('Users ', arr)
                let temp = []
                for (let i = 0; i < arr.length; i++) {
                    let val = await userdb.child(String(arr[i].ID)).once('value')
                    if (val.val() != null) {
                        let obj = { name: null, time: null, uri: null, vision: false, ID: arr[i].ID, accept: false }
                        obj.name = val.val().first == undefined ? decrypt(val.val().email) : decrypt(val.val().first) + " " + decrypt(val.val().second)
                        obj.time = val.val().last == undefined ? val.val().ID : val.val().last
                        obj.uri = val.val().uri == undefined ? null : val.val().uri
                        obj.vision = val.val().vision == undefined ? false : true
                        obj.accept = arr[i].accept == undefined ? false : arr[i].accept
                        temp.push(obj)
                    }
                }
                setMygoals([...temp])
            }
            else setMygoals([])
        })
        return () => subscribe()
    }, [])

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    function format_date(time) {
        let before_str = new Date(Number(time)).getDate() + " " + months[new Date(Number(time)).getMonth()] + ", " + new Date(Number(time)).getFullYear();
        return before_str + " " + moment(new Date(Number(time))).format('HH : mm A')
    }

    const people = [{ post: 'Manager', name: 'Tom Hanks', time: 'Last logged in: 11th February, 2023 - 05:30 AM', pic: 'https://firebasestorage.googleapis.com/v0/b/toy-s-project.appspot.com/o/Mask%20group(8).png?alt=media&token=f9e2e7f3-89ab-4222-b19e-fac34b1a42e1', ans: 'Yes' },
    { post: 'Manager', name: 'Tom Hanks', time: 'Last logged in: 11th February, 2023 - 05:30 AM', pic: 'https://firebasestorage.googleapis.com/v0/b/toy-s-project.appspot.com/o/Mask%20group(8).png?alt=media&token=f9e2e7f3-89ab-4222-b19e-fac34b1a42e1', ans: 'No' },
    { post: 'Manager', name: 'Tom Hanks', time: 'Last logged in: 11th February, 2023 - 05:30 AM', pic: 'https://firebasestorage.googleapis.com/v0/b/toy-s-project.appspot.com/o/Mask%20group(8).png?alt=media&token=f9e2e7f3-89ab-4222-b19e-fac34b1a42e1', ans: 'Yes' },
    { post: 'Manager', name: 'Tom Hanks', time: 'Last logged in: 11th February, 2023 - 05:30 AM', pic: 'https://firebasestorage.googleapis.com/v0/b/toy-s-project.appspot.com/o/Mask%20group(8).png?alt=media&token=f9e2e7f3-89ab-4222-b19e-fac34b1a42e1', ans: 'Yes' },
    { post: 'Manager', name: 'Tom Hanks', time: 'Last logged in: 11th February, 2023 - 05:30 AM', pic: 'https://firebasestorage.googleapis.com/v0/b/toy-s-project.appspot.com/o/Mask%20group(8).png?alt=media&token=f9e2e7f3-89ab-4222-b19e-fac34b1a42e1', ans: 'No' },
    { post: 'Manager', name: 'Tom Hanks', time: 'Last logged in: 11th February, 2023 - 05:30 AM', pic: 'https://firebasestorage.googleapis.com/v0/b/toy-s-project.appspot.com/o/Mask%20group(8).png?alt=media&token=f9e2e7f3-89ab-4222-b19e-fac34b1a42e1', ans: 'No' },
    ]

    const [mail, setMail] = useState('')

    async function add_member() {

        if (mail != '') {
            if (ifemailexist(mail)) {
                toast('User already exists')
            }

            else {
                let check = await userdb.orderByChild('email').equalTo(encrypt(mail)).once('value')
                // new user
                if (check.val() == null) {
                    let obj = {
                        ID: String(new Date().getTime()),
                        email: encrypt(mail),
                        accept: true
                    }
                    let user_obj = {
                        ID: String(new Date().getTime()),
                        email: encrypt(mail),
                        coach: {
                            [String(items.myid.ID)]: {
                                ID: items.myid.ID,
                                accept: true
                            }
                        }
                    }
                    await userdb.child(String(obj.ID)).set(user_obj)
                    await coachdb.child(String(items.myid.ID)).child('myusers').child(String(obj.ID)).set(obj)
                    await fetch(`https://us-central1-yescoach-5f1d9.cloudfunctions.net/app/send_add_new?company_name=${decrypt(items.myid.company_name)}&mail=${mail}`)
                    setMail('')
                    toast('This user has been invited')
                }

                else {
                    let arr = Object.values(check.val())
                    let obj = {
                        ID: String(arr[0].ID),
                        email: encrypt(mail),
                        accept: false
                    }
                    let user_obj = {
                        ID: items.myid.ID,
                        accept: false
                    }
                    await userdb.child(String(obj.ID)).child('coach').child(String(user_obj.ID)).set(user_obj)
                    await coachdb.child(String(items.myid.ID)).child('myusers').child(String(obj.ID)).set(obj)
                    await fetch(`https://us-central1-yescoach-5f1d9.cloudfunctions.net/app/send_add?company_name=${decrypt(items.myid.company_name)}&mail=${mail}`)
                    setMail('')
                    toast('This user has been invited')
                }
            }
        }
        else {
            toast('Empty fields are not accepted')
        }
    }

    const renderPerson = (person, id) => {
        console.log('Person ', person)
        return (
            <div key={id} >

                <div style={{ display: 'flex', flexDirection: 'row', width: 0.6848 * width, justifyContent: 'center', height: 90, backgroundColor: '#292E33', alignSelf: 'center', alignItems: 'center', marginTop: 20, borderRadius: 4 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.6513 * width, justifyContent: 'space-between', height: 90, backgroundColor: '#292E33', alignSelf: 'center', alignItems: 'center' }}>


                        <img src={person.uri} style={{ width: 50, height: 50, alignSelf: 'center' }} />


                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.1800 * width, justifyContent: 'left' }}>

                                <text style={{ textAlign: 'left', fontSize: 24, fontWeight: '600', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0, alignSelf: 'center' }}>{person.name}</text>

                                <div>
                                </div>
                            </div>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', width: 0.1800 * width, fontFamily: 'Roboto', color: '#5E5E5E', marginTop: 3, alignSelf: 'center' }}>Last logged in : {format_date(person.time)}</text>
                            {(person.accept == undefined || person.accept == false) ? <text style={{ textAlign: 'left', color: 'red', width: 0.1800 * width, }}>Invitation sent</text> : <text style={{ textAlign: 'left', color: '#05FF00', width: 0.1800 * width, }}>Accepted</text>}

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: 0.1900 * width, justifyContent: 'space-evenly', height: 47, backgroundColor: '#1F2327', alignSelf: 'center', alignItems: 'center', marginLeft: 0.0526 * width, borderRadius: 4 }}>
                            <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', width: 0.1400 * width, fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0, alignSelf: 'center' }}>Completed My vision? </text>

                            <text onClick={() => ('/')} style={{ textAlign: 'center', fontSize: 18, fontWeight: '600', width: 0.0300 * width, fontFamily: 'Roboto', color: person.vision == true ? '#05FF00' : '#FF4747', marginTop: 0, alignSelf: 'center' }}>{person.vision == true ? 'Yes' : 'No'} </text>

                        </div>


                        <div onClick={() => { console.log('Click person ', person.ID); setDeletePopUp(person.ID) }} style={{ cursor: 'pointer', display: 'flex', width: 0.0328 * width, height: 50, borderRadius: 8, backgroundColor: '#FF4747', justifyContent: 'center', alignItems: 'center', marginLeft: 0.0131 * width }}>
                            <RiDeleteBinFill style={{ width: 20, height: 22, color: 'white' }} />
                        </div>
                    </div>
                </div>


            </div>
        );
    }

    const renderPersonMobile = (person, id) => {
        console.log('Person ', person)
        return (
            <div key={id} >

                <div style={{ display: 'flex', flexDirection: 'row', width: 0.8848 * width, justifyContent: 'center', height: 250, backgroundColor: '#292E33', alignSelf: 'center', alignItems: 'center', marginTop: 20, borderRadius: 4 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.8513 * width, justifyContent: 'space-evenly', height: 250, backgroundColor: '#292E33', alignSelf: 'center', alignItems: 'center' }}>


                        <img src={person.uri} style={{ width: 50, height: 50, alignSelf: 'center', marginTop: 10 }} />


                        <div style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.6800 * width, justifyContent: 'center', alignSelf: 'center' }}>

                                <text style={{ textAlign: 'left', fontSize: 20, fontWeight: '600', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0, alignSelf: 'center' }}>{person.name}</text>

                                <div>
                                </div>
                            </div>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', width: 0.68 * width, fontFamily: 'Roboto', color: '#5E5E5E', marginTop: 3, alignSelf: 'center' }}>Last logged in : {format_date(person.time)}</text>
                            {(person.accept == undefined || person.accept == false) ? <text style={{ textAlign: 'left', color: 'red', width: 0.5800 * width, }}>Invitation sent</text> : <text style={{ textAlign: 'left', color: '#05FF00', width: 0.1800 * width, }}>Accepted</text>}

                        </div>

                        <div style={{ width: 0.8500 * width, display: 'flex', alignSelf: 'center', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.6900 * width, justifyContent: 'space-evenly', height: 47, backgroundColor: '#1F2327', alignSelf: 'center', alignItems: 'center', borderRadius: 4 }}>
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', width: 0.5400 * width, fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0, alignSelf: 'center' }}>Completed My vision? </text>

                                <text onClick={() => ('/')} style={{ textAlign: 'center', fontSize: 18, fontWeight: '600', width: 80, fontFamily: 'Roboto', color: person.vision == true ? '#05FF00' : '#FF4747', marginTop: 0, alignSelf: 'center' }}>{person.vision == true ? 'Yes' : 'No'} </text>

                            </div>


                            <div onClick={() => { console.log('Click person ', person.ID); setDeletePopUp(person.ID) }} style={{ cursor: 'pointer', display: 'flex', width: 50, height: 50, borderRadius: 8, backgroundColor: '#FF4747', justifyContent: 'center', alignItems: 'center', marginLeft: 0.0131 * width }}>
                                <RiDeleteBinFill style={{ width: 20, height: 22, color: 'white' }} />
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        );
    }


    const [add, setAdd] = useState(false)

    async function delete_user(id) {
        console.log('Person id ', id)
        await coachdb.child(String(items.myid.ID)).child('myusers').child(String(id)).remove()
        await userdb.child(String(id)).child('coach').child(String(items.myid.ID)).remove()
        toast('This user has been removed')
        setDeletePopUp(false)
    }

    const isMobile = width <= 768;

    const getHeight = Math.max(height, 1300)


    return (
        !isMobile ? <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0 }}>
            <div style={{ backgroundColor: '#8455f6', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', height: height, width: 286, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                </div>

                <div onClick={() => navigate('/DashboardCompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                    </div>
                </div>

                <div onClick={() => navigate('/TeamMember')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{team_active}</div>
                        <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Payment')} style={{ cursor: 'pointer', height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                    </div>
                </div>

                <div onClick={() => navigate('/CompanyAccount')} style={{ cursor: 'pointer', height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                    </div>
                </div>

                <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                    </div>
                </div>

                <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />

            </div>


            {!add ? <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 286, width: width - 286, position: 'absolute', top: 0, height: height, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 100, width: width - 286, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <text></text>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>Team Members</text>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 0.7723 * width, height: height - 400, alignItems: 'center', backgroundColor: '#1F2327', alignSelf: 'center', marginTop: 20 }}>

                    {my_users.length != 0 ? <FlatList
                        list={my_users}
                        renderItem={renderPerson}
                    /> : null}

                </div>

                <div onClick={() => setAdd(true)} style={{ cursor: 'pointer', display: 'flex', width: 0.1809 * width, height: 70, backgroundColor: '#8455F6', justifyContent: 'space-evenly', alignContent: 'center', alignSelf: 'end', marginTop: 50 }}>
                    <FaPlus style={{ width: 22, height: 22, alignSelf: 'center', color: '#FFFFFF' }} />
                    <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0, alignSelf: 'center' }}>Add Team Member</text>

                </div>


            </div> : <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 286, width: width - 286, position: 'absolute', top: 0, height: height, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 100, width: width - 286, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <text></text>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>Team Members</text>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 0.4 * width, justifyContent: 'center', height: 250, backgroundColor: '#292E33', alignSelf: 'center', marginTop: 20 }}>


                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.2980 * width, justifyContent: 'flex-start', height: 250, alignSelf: 'center' }}>

                        <FaArrowLeft onClick={() => setAdd(false)} style={{ cursor: 'pointer', alignSelf: 'flex-start', marginTop: 20 }} size={20} color='white' />

                        <div style={{ display: 'flex', width: 0.2980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>Email</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 10, alignItems: 'center', alignSelf: 'center', }}>

                            <input value={mail} onChange={(e) => setMail(e.currentTarget.value)} placeholder='User email address' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#1F2327', width: 0.2980 * width, height: 43, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                        </div>



                        <div onClick={() => add_member()} style={{ cursor: 'pointer', display: 'flex', backgroundColor: '#8455F6', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 40, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF' }}>Add</text>
                        </div>

                    </div>


                </div>
            </div>
            }

            <ReactModal
                isOpen={delete_pop_up != false}
                contentLabel="Inline Styles Modal Example"
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        color: 'lightsteelblue',
                        width: 500,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: 250,
                        marginLeft: (width - 500) / 2,
                        backgroundColor: '#131517'
                    }
                }}
            >

                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 500, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'white' }}> Do you want to remove this user from your company ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={async () => { await delete_user(delete_pop_up) }} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div>

            </ReactModal>

            <wc-toast></wc-toast>

        </div> : <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'row', width: width, height: getHeight, position: 'absolute', top: 0, left: 0 }}>


            {!add ? <div style={{ display: 'flex', flexDirection: 'column', width: width, height: getHeight, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 150, width: width, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <Popup
                        trigger={<div className="menu-item"><VscThreeBars color='white' size={35} style={{ marginLeft: 25 }} /></div>}
                        position="right top"
                        on="click"
                        closeOnDocumentClick={false}
                        mouseLeaveDelay={300}
                        mouseEnterDelay={30}
                        contentStyle={{ padding: '0px', border: 'none', marginRight: 50, width: 280, display: 'flex', borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                        arrow={true}
                        overlayStyle={{ marginLeft: -20 }}

                    >
                        <div style={{ backgroundColor: '#8455f6', display: 'flex', flexDirection: 'column', height: 580, width: 280, textAlign: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                                <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                            </div>

                            <div onClick={() => navigate('/DashboardCompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                                <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/TeamMember')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{team_active}</div>
                                    <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/Payment')} style={{ cursor: 'pointer', height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/CompanyAccount')} style={{ cursor: 'pointer', height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                                </div>
                            </div>

                            <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />


                        </div>
                    </Popup>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>Team Members</text>
                    </div>
                </div>


                <div onClick={() => setAdd(true)} style={{ cursor: 'pointer', display: 'flex', width: 250, height: 70, backgroundColor: '#8455F6', justifyContent: 'space-evenly', alignContent: 'center', alignSelf: 'end', marginTop: 50 }}>
                    <FaPlus style={{ width: 20, height: 22, alignSelf: 'center', color: '#FFFFFF' }} />
                    <text style={{ textAlign: 'center', fontSize: 20, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 0, alignSelf: 'center' }}>Add Team Member</text>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: 0.97 * width, height: getHeight-450, alignItems: 'center', backgroundColor: '#1F2327', alignSelf: 'center', marginTop: 20 }}>

                    {my_users.length != 0 ? <FlatList
                        list={my_users}
                        renderItem={renderPersonMobile}
                    /> : null}

                </div>

            </div> : <div style={{ display: 'flex', flexDirection: 'column', width: width, position: 'absolute', top: 0, height: getHeight, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 150, width: width, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <Popup
                        trigger={<div className="menu-item"><VscThreeBars color='white' size={35} style={{ marginLeft: 25 }} /></div>}
                        position="right top"
                        on="click"
                        closeOnDocumentClick={false}
                        mouseLeaveDelay={300}
                        mouseEnterDelay={30}
                        contentStyle={{ padding: '0px', border: 'none', marginRight: 50, width: 280, display: 'flex', borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                        arrow={true}
                        overlayStyle={{ marginLeft: -20 }}

                    >
                        <div style={{ backgroundColor: '#8455f6', display: 'flex', flexDirection: 'column', height: 580, width: 280, textAlign: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                                <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                            </div>

                            <div onClick={() => navigate('/DashboardCompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                                <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/TeamMember')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{team_active}</div>
                                    <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/Payment')} style={{ cursor: 'pointer', height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/CompanyAccount')} style={{ cursor: 'pointer', height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                                </div>
                            </div>

                            <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />


                        </div>
                    </Popup>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>Team Members</text>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: width, justifyContent: 'center', height: 250,  alignSelf: 'center', marginTop: 20 }}>


                    <div style={{backgroundColor: '#292E33', display: 'flex', flexDirection: 'column', width: 0.97 * width, justifyContent: 'flex-start', height: 250, alignSelf: 'center' }}>

                        <FaArrowLeft onClick={() => setAdd(false)} style={{ cursor: 'pointer', alignSelf: 'flex-start', marginTop: 20 ,marginLeft:15}} size={20} color='white' />

                        <div style={{ display: 'flex', width: 0.6980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>Email</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.7980 * width, borderRadius: 4, marginTop: 10, alignItems: 'center', alignSelf: 'center', }}>

                            <input value={mail} onChange={(e) => setMail(e.currentTarget.value)} placeholder='User email address' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#1F2327', width: 0.6780 * width, height: 45, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 8 }} />

                        </div>



                        <div onClick={() => add_member()} style={{ cursor: 'pointer', display: 'flex', backgroundColor: '#8455F6', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 40, alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }}>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF' }}>Add</text>
                        </div>

                    </div>


                </div>
            </div>
            }

            <ReactModal
                isOpen={!isMobile && delete_pop_up != false}
                contentLabel="Inline Styles Modal Example"
                style={{
                    overlay: {
                        backgroundColor: 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        color: 'lightsteelblue',
                        width: 500,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: 250,
                        marginLeft: (width - 500) / 2,
                        backgroundColor: '#131517'
                    }
                }}
            >

                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 500, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'white' }}> Do you want to remove this user from your company ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={async () => { await delete_user(delete_pop_up) }} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div>

            </ReactModal>

            <Modal open={isMobile && delete_pop_up != false}
                onClose={() => setDeletePopUp(false)}
                center
                styles={{
                    modal: {
                        backgroundColor: '#1f2327'
                    },
                    closeButton: { backgroundColor: 'white', borderRadius: 15 }
                }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 300, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontSize: 20, color: 'white' }}> Do you want to remove this user from your company ?</text>
                    </div>
                    <div style={{ marginTop: 20, alignSelf: 'center', justifyContent: 'space-around', flexDirection: 'row', display: 'flex', width: 280, alignItems: 'center' }}>

                        <div onClick={async () => { await delete_user(delete_pop_up) }} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: 'red', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>Yes</text>
                        </div>

                        <div onClick={() => setDeletePopUp(false)} style={{ cursor: 'pointer', width: 75, padding: 10, backgroundColor: "#8455f6", borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                            <text style={{ color: 'white', textAlign: 'center' }}>No</text>
                        </div>

                    </div>
                </div>

            </Modal>

            <wc-toast></wc-toast>

        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId })(TeamMembers)
