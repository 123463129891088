import activities_dashboard from './home_active.png'
import inactive_dashboard from './inactive_dashboard.png'

import vision_active from './vision_active.png'
import vision_inactive from './vision_inactive.png'

import goal_active from './goal_active.png'
import goal_inactive from './goal_inactive.png'

import week_plan_inactive from './weekly_plan_inactive.png'
import week_plan_active from './weekly_plan_active.png'

import journal_active from './journal_active.png'
import journal_inactive from './journal_inactive.png'

import activity_plan_inactive from './activity_plan_inactive.png'
import activity_plan_active from './activity_plan_active.png'

import win_active from './win_active.png'
import win_inactive from './win_inactive.png'

import habit_active from './habit_active.png'
import habit_inactive from './habit_inactive.png'

import video_active from './videos_active.png'
import video_inactive from './videos_inactive.png'


import company_pay_active from './active_company_payment.png'
import company_pay_inactive from './company_payments_inactive.png'

import company_team_active from './active_team_members.png'
import company_team_inactive from './inactive_team_members.png'

import company_account_active from './active_company_user.png'
import company_account_inactive from './inactive_company_user.png'

import logout from './logout.png'
import { FaRegMessage } from 'react-icons/fa6'
import { CiSettings } from 'react-icons/ci'

export const activities_dashboard_icon =<img src={activities_dashboard} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_dashboard_icon =<img src={inactive_dashboard} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_vision_icon =<img src={vision_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_vision_icon =<img src={vision_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_goal_icon =<img src={goal_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_goal_icon =<img src={goal_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_week_plan_icon =<img src={week_plan_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_week_plan_icon =<img src={week_plan_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_journal_icon =<img src={journal_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_journal_icon =<img src={journal_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_activityplan_icon =<img src={activity_plan_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_activityplan_icon =<img src={activity_plan_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_win_icon =<img src={win_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_win_icon =<img src={win_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_habit_icon =<img src={habit_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_habit_icon =<img src={habit_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const active_video_icon =<img src={video_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const inactive_video_icon =<img src={video_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>


export const logout_icon =<img src={logout} style={{ width:20,height:  20, alignSelf:'center'}}/>



export const pay_active =<img src={company_pay_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const pay_inactive =<img src={company_pay_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const team_active =<img src={company_team_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const team_inactive =<img src={company_team_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const user_active =<img src={company_account_active} style={{ width:20,height:  20, alignSelf:'center'}}/>
export const user_inactive =<img src={company_account_inactive} style={{ width:20,height:  20, alignSelf:'center'}}/>

export const invite_active = <FaRegMessage color='#8455F6' style={{ width:20,height:  20, alignSelf:'center'}} />
export const invite_inactive = <FaRegMessage color='#e2dfe6' style={{ width:20,height:  20, alignSelf:'center'}} />

// <CiSettings />

export const settings_active = <CiSettings color='#8455F6' style={{ width:21,height:  21, alignSelf:'center'}} />
export const settings_inactive = <CiSettings color='#e2dfe6' style={{ width:21,height:  21, alignSelf:'center'}} />
