
import './App.css';
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
  HashRouter,
  useNavigate
} from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { auth } from './config';
//import { ToastContainer, toast } from 'react-toastify';
import { toast } from 'wc-toast'

import { Provider, connect } from "react-redux";
import Dashboardcompany from './company_screens/Dashboardcompany';
import CompanyAccount from './company_screens/CompanyAccount';
import Payment from './company_screens/Payment';
import TeamMembers from './company_screens/TeamMembers';
import LoginCompany from './screens/LoginCompany';
import SignUpCompany from './screens/SignUpCompany';
import ForgetCompany from './screens/ForgetCompany';

import { LogOut } from './screens/redux/actions';
import Settings from './screens/Settings';
import StripePayment from './screens/StripePayment';
import StripePaymentCompany from './company_screens/StripePaymentCompany';
import Packages from './company_screens/Packages';
//import 'react-toastify/dist/ReactToastify.css';

const timeout = 1000 * 60 * 15
const promptBeforeIdle = 1000

function App({ items, LogOut }) {

  const ___doSingIn = async () => {
    try {
      let response = await auth.signInWithEmailAndPassword("n.joy@boomsoftware.co.uk", "2103199j");
      if (response && response.user) {

      }
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    ___doSingIn()
  }, [])


  return (
    <div className="App">
      <HashRouter>
        <Routes>

          <Route path="/" element={<LoginCompany />} />
          <Route path="/company_login" element={<LoginCompany />} />
          <Route path="/company_signup" element={<SignUpCompany />} />

          <Route path="/Dashboardcompany" element={<Dashboardcompany />} />
          <Route path="/CompanyAccount" element={<CompanyAccount />} />

          <Route path="/ForgetCompany" element={<ForgetCompany />} />
          <Route path="/Payment" element={<Payment />} />

          <Route path="/TeamMembers" element={<TeamMembers />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/StripePayment" element={<StripePayment />} />
          <Route path="/StripePaymentCompany" element={<StripePaymentCompany />} />
          <Route path="/Packages" element={<Packages />} />


        </Routes>
      </HashRouter>
      <wc-toast></wc-toast>
    </div>
  );
}

const mapStateToProps = state => {
  //  console.log(state)
  return {
    items: state._todoProduct
  }
}

export default connect(mapStateToProps, { LogOut })(App)