
import '../App.css';
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/logo.png'
import { useNavigate } from 'react-router-dom';
import { db, firestore } from '../config';
import moment from 'moment/moment';
import { FaUserFriends } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
//import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { toast } from 'wc-toast'
import { RxExternalLink } from "react-icons/rx";
import { active_activityplan_icon, active_goal_icon, active_journal_icon, active_vision_icon, active_week_plan_icon, activities_dashboard_icon, inactive_activityplan_icon, inactive_goal_icon, inactive_habit_icon, inactive_journal_icon, inactive_video_icon, inactive_vision_icon, inactive_week_plan_icon, inactive_win_icon, logout_icon, pay_inactive, team_inactive, user_inactive } from '../assets/Icon';
import ReactiveButton from 'reactive-button';
import { RxCross1 } from "react-icons/rx";
import { connect } from 'react-redux';
import { SetId } from '../screens/redux/actions';
import { decrypt, decryptPass } from '../Crypto';
import { Database } from 'firebase/database';
import ToggleButton from 'react-toggle-button'
import cry1 from './cry1.png'
import smile from './smile.png'
import smile1 from './smile1.png'
import confused from './confounded.png'
import ReactCardFlip from 'react-card-flip';
import Popup from 'reactjs-popup';
import { VscThreeBars } from 'react-icons/vsc';
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const inactive_color = '#cbb6fe'

const coachdb = db.ref('coachdb');


const out_link = <RxExternalLink size={24} color='black' />

const userdb = db.ref('userdb');
const customerdb = db.ref('customerdb');

const candidate_db = firestore.collection('candidate_db')

const recruiter_db = db.ref('recruiter_db');

const recruiter_active = <FaUserTie color='white' size={22} />
const recruiter_active_non = <FaUserTie color='#B9B9B9' size={22} />

/*
matt@theroxifoundation.co.uk
Vsg32145!
*/

function Dashboardcompany({ items, SetId }) {

    console.log(decrypt('oqe.fwqnek@fpwqr.vvco'))
    console.log(decryptPass('Vsg78965!'))

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(Math.max(window.innerHeight, 1100));

    const [my_users, setMygoals] = useState([])

    const [completed_goals, setCompletedGoals] = useState(0);
    const [incompleted_goals, setIncompletedGoals] = useState(0)

    const [completed_goals_prev, setCompletedGoalsPrev] = useState(0);
    const [incompleted_goals_prev, setIncompletedGoalsPrev] = useState(0)

    const [completed_plans, setCompletedPlans] = useState(0);
    const [incompleted_plans, setIncompletedPlans] = useState(0)

    const [completed_plans_prev, setCompletedPlansPrev] = useState(0);
    const [incompleted_plans_prev, setIncompletedPlansPrev] = useState(0)

    let seven_back = new Date(moment(new Date()).subtract(8, 'days').format("MM/DD/YYYY")).getTime()

    let two_week_back = new Date(moment(new Date()).subtract(15, 'days').format("MM/DD/YYYY")).getTime()

    const [users, setUsers] = useState(0)
    const [prev_users, setPrevUsers] = useState(0)

    const [emotions, setEmotions] = useState({ Excited: 0, Happy: 0, Tired: 0, Sad: 0, Neutral: 0, Fed_Up: 0, Excited1: 0, Happy1: 0, Tired1: 0, Sad1: 0, Neutral1: 0, Fed_Up1: 0 })

    async function user_emotion(Id) {
        const emotiondb = db.ref(`emotion${Id}`)
        let obj = { Excited: 0, Happy: 0, Tired: 0, Sad: 0, Neutral: 0, Fed_Up: 0, Excited1: 0, Happy1: 0, Tired1: 0, Sad1: 0, Neutral1: 0, Fed_Up1: 0 }
        let val = await emotiondb.orderByChild('ID').startAt(two_week_back).once('value')
        if (val.val() != null) {
            let arr = Object.values(val.val())

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].ID >= seven_back) {
                    if (arr[i].emotion == 'Excited') obj.Excited++;
                    else if (arr[i].emotion == 'Happy') obj.Happy++;
                    else if (arr[i].emotion == 'Tired') obj.Tired++;
                    else if (arr[i].emotion == 'Sad') obj.Sad++;
                    else if (arr[i].emotion == 'Neutral') obj.Neutral++;
                    else if (arr[i].emotion == 'Fed up') obj.Fed_Up++;
                }
                else {
                    if (arr[i].emotion == 'Excited') obj.Excited1++;
                    else if (arr[i].emotion == 'Happy') obj.Happy1++;
                    else if (arr[i].emotion == 'Tired') obj.Tired1++;
                    else if (arr[i].emotion == 'Sad') obj.Sad1++;
                    else if (arr[i].emotion == 'Neutral') obj.Neutral1++;
                    else if (arr[i].emotion == 'Fed up') obj.Fed_Up1++;
                }
            }
            return obj;
        }
        else return obj;
    }

    async function allUserEmotion() {
        let obj = { Excited: 0, Happy: 0, Tired: 0, Sad: 0, Neutral: 0, Fed_Up: 0, Excited1: 0, Happy1: 0, Tired1: 0, Sad1: 0, Neutral1: 0, Fed_Up1: 0 }
        for (let i = 0; i < my_users.length; i++) {
            let temp = await user_emotion(my_users[i].ID)
            obj.Excited += temp.Excited;
            obj.Happy += temp.Happy
            obj.Neutral += temp.Neutral
            obj.Sad += temp.Sad
            obj.Tired += temp.Tired
            obj.Fed_Up += temp.Fed_Up

            obj.Excited1 += temp.Excited1;
            obj.Happy1 += temp.Happy1
            obj.Neutral1 += temp.Neutral1
            obj.Sad1 += temp.Sad1
            obj.Tired1 += temp.Tired1
            obj.Fed_Up1 += temp.Fed_Up1
        }
        setEmotions({ ...obj })
    }

    async function if_last_login(Id) {
        console.log('user id ', Id)
        let val = await userdb.orderByChild('ID').equalTo(String(Id)).once('value')
        if (val != undefined && val.val() != null) {
            let last = val.val()
            last = last[String(Id)]
            last = last.last == undefined ? last.ID : last.last
            last = Number(last)
            let temp = [0, 0]
            if (last >= seven_back) temp[0] = 1;
            else if (last >= two_week_back) temp[1] = 1;
            return temp
        }
        else return [0, 0]
    }

    async function allUserLogin() {
        let temp = 0;
        let prev_temp = 0;
        for (let i = 0; i < my_users.length; i++) {
            let arr = await if_last_login(my_users[i].ID)
            if (arr != undefined) temp += arr[0]
            if (arr != undefined) prev_temp += arr[1]
        }
        setUsers(temp)
        setPrevUsers(prev_temp)
    }


    useEffect(() => {
        let subscribe = coachdb.child(String(items.myid.ID)).child('myusers').on('value', async (snapshot) => {
            if (snapshot != undefined && snapshot.val() != null) {
                let arr = Object.values(snapshot.val());
                setMygoals([...arr])
            }
        })
        return () => subscribe()
    }, [])

    async function getCompleteGoals(id) {
        const goal_db = firestore.collection(`goals`);
        let val = await goal_db.where('complete', '==', true).where('user_id', '==', id).where('ID', '>=', seven_back).get()
        val = val.size
        console.log('Size ', val)
        return val;
    }

    async function getCompleteGoalsPrev(id, latest) {
        const goal_db = firestore.collection(`goals`);
        let val = await goal_db.where('complete', '==', true).where('user_id', '==', id).where('ID', '>=', two_week_back).get()
        val = val.size
        console.log('Size ', val)
        return val - latest;
    }

    async function allCompletegoals() {
        let temp = 0;
        let prev = 0;
        for (let i = 0; i < my_users.length; i++) {
            let val = await getCompleteGoals(my_users[i].ID)
            temp += val
            prev += await getCompleteGoalsPrev(my_users[i].ID, val)
        }
        setCompletedGoals(temp)
        setCompletedGoalsPrev(prev)
    }


    async function getInCompleteGoals(id) {
        const goal_db = firestore.collection(`goals`);
        let val = await goal_db.where('complete', '==', false).where('user_id', '==', id).where('ID', '>=', seven_back).get()
        val = val.size
        console.log('Size ', val)
        return val;
    }

    async function getInCompleteGoalsPrev(id, latest) {
        const goal_db = firestore.collection(`goals`);
        let val = await goal_db.where('complete', '==', false).where('user_id', '==', id).where('ID', '>=', two_week_back).get()
        val = val.size
        console.log('Size ', val)
        return val - latest;
    }

    async function allInCompletegoals() {
        let temp = 0;
        let prev = 0;
        for (let i = 0; i < my_users.length; i++) {
            let val = await getInCompleteGoals(my_users[i].ID)
            temp += val;
            prev += await getInCompleteGoalsPrev(my_users[i].ID, val)
        }
        setIncompletedGoals(temp)
        setIncompletedGoalsPrev(prev)
    }



    async function getInCompletePlans(id) {
        const goal_db = firestore.collection(`activityplans`);
        let val = await goal_db.where('complete', '==', false).where('user_id', '==', id).where('ID', '>=', seven_back).get()
        val = val.size
        console.log('Size ', val)
        return val;
    }

    async function getInCompletePlansPrev(id, latest) {
        const goal_db = firestore.collection(`activityplans`);
        let val = await goal_db.where('complete', '==', false).where('user_id', '==', id).where('ID', '>=', two_week_back).get()
        val = val.size
        console.log('Size ', val)
        return val - latest;
    }

    async function allInCompleteplans() {
        let temp = 0;
        let prev = 0;
        for (let i = 0; i < my_users.length; i++) {
            let val = await getInCompletePlans(my_users[i].ID)
            temp += val
            prev += await getInCompletePlansPrev(my_users[i].ID, val)
        }
        setIncompletedPlans(temp)
        setIncompletedPlansPrev(prev)
    }

    async function getCompletePlans(id) {
        const goal_db = firestore.collection(`activityplans`);
        let val = await goal_db.where('complete', '==', true).where('user_id', '==', id).where('ID', '>=', seven_back).get()
        val = val.size
        console.log('Size ', val)
        return val;
    }

    async function getCompletePlansPrev(id, latest) {
        const goal_db = firestore.collection(`activityplans`);
        let val = await goal_db.where('complete', '==', true).where('user_id', '==', id).where('ID', '>=', two_week_back).get()
        val = val.size
        console.log('Size ', val)
        return val - latest;
    }

    async function allCompleteplans() {
        let temp = 0;
        let prev = 0;
        for (let i = 0; i < my_users.length; i++) {
            let val = await getCompletePlans(my_users[i].ID)
            temp += val
            prev += await getCompletePlansPrev(my_users[i].ID, val)
        }
        setCompletedPlans(temp)
        setCompletedPlansPrev(prev)
    }

    // const goal_db = firestore.collection(`activityplans`)


    useEffect(() => {
        if (my_users.length > 0) {
            allCompletegoals()
            allInCompletegoals()
            allInCompleteplans()
            allCompleteplans()
            allUserLogin()
            allUserEmotion()
        }
    }, [my_users])

    useEffect(() => {
        const windowSizeHandler = () => {
            setWidth(window.innerWidth)
            setHeight(Math.max(window.innerHeight, 1100))
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);


    let curr = new Date().getTime();

    const navigate = useNavigate();

    const [touched1, setTouched1] = useState(false);
    const [password_pop_up, setPasswordPopUp] = useState(true);

    const [my_open_goals, setMyOpenGoals] = useState([])


    const [emotion, setEmotion] = useState(false)

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const isMobile = width <= 768;

    const getHeight = Math.max(height, 1300)

    const setting_ref = useRef()

    return (
        !isMobile ? <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0 }}>
            <div style={{ backgroundColor: '#8455f6', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', height: height, width: 286, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                </div>

                <div style={{ height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#FFFFFF', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{activities_dashboard_icon}</div>
                        <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                    </div>
                </div>

                <div onClick={() => navigate('/TeamMembers')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{team_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Payment')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                    </div>
                </div>

                <div onClick={() => navigate('/CompanyAccount')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                    </div>
                </div>

                <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                    </div>
                </div>

                <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 286, width: width - 286, position: 'absolute', top: 0, height: height, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 100, width: width - 286, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <text></text>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>DASHBOARD</text>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 0.7723 * width, justifyContent: 'flex-start', height: 'auto', backgroundColor: '#1F2327', alignSelf: 'center', alignItems: 'center', marginTop: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.7197 * width, justifyContent: 'space-between', height: 194, alignSelf: 'center', alignItems: 'flex-start', marginTop: 30 }}>


                        <ReactCardFlip isFlipped={users != 0} flipDirection="horizontal" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{prev_users} Active Users</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: prev_users == users ? '#FAFF00' : prev_users > users ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>0</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Active Users</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{prev_users} Active Users</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: prev_users == users ? '#FAFF00' : prev_users > users ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{users}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Active Users</text>

                            </div>
                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="vertical" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{my_users.length==0?0:Number(users / my_users.length).toFixed(2)} % of users logged</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: '#FF4747', marginTop: 5, alignSelf: 'center' }}>0 %</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>of users logged</text>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{Number((users / my_users.length) * 100).toFixed(2)} % of users logged</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: '#FF4747', marginTop: 5, alignSelf: 'center' }}>{Number((users / my_users.length) * 100).toFixed(2)} %</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>of users logged</text>
                            </div>
                        </ReactCardFlip>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.7197 * width, justifyContent: 'space-evenly', height: 220, backgroundColor: '#292E33', marginTop: 30 }}>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.1052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.0868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile1} style={{ width: 0.0166 * width, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Excited</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Excited1 == emotions.Excited ? '#FAFF00' : emotions.Excited1 > emotions.Excited ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Excited}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Excited1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.1052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.0868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile} style={{ width: 0.0166 * width, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Happy</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Happy1 == emotions.Happy ? '#FAFF00' : emotions.Happy1 > emotions.Happy ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Happy}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Happy1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.1052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.0868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile} style={{ width: 0.0166 * width, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Neutral</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Neutral1 == emotions.Neutral ? '#FAFF00' : emotions.Neutral1 > emotions.Neutral ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Neutral}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Neutral1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.1052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.0868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={cry1} style={{ width: 0.0166 * width, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Tired</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Tired1 == emotions.Tired ? '#FAFF00' : emotions.Tired1 > emotions.Tired ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Tired}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Tired1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.1052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.0868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={confused} style={{ width: 0.0166 * width, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Fed up</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Fed_Up1 == emotions.Fed_Up ? '#FAFF00' : emotions.Fed_Up1 > emotions.Fed_Up ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Fed_Up}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Fed_Up1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.1052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.0868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile1} style={{ width: 0.0166 * width, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Sad</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Sad1 == emotions.Sad ? '#FAFF00' : emotions.Sad1 > emotions.Sad ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Sad}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Sad1}</text>


                        </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.7197 * width, justifyContent: 'space-between', height: 194, alignSelf: 'center', alignItems: 'flex-start', marginTop: 30 }}>


                        <ReactCardFlip isFlipped={users != 0} flipDirection="vertical" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_goals_prev} Open goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_goals_prev == incompleted_goals ? '#FAFF00' : incompleted_goals_prev > incompleted_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open goals</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_goals_prev} Open goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_goals_prev == incompleted_goals ? '#FAFF00' : incompleted_goals_prev > incompleted_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open goals</text>

                            </div>

                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="horizontal" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_goals_prev} Completed goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: completed_goals_prev == completed_goals ? '#FAFF00' : completed_goals_prev > completed_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed goals</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_goals_prev} Completed goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: completed_goals_prev == completed_goals ? '#FAFF00' : completed_goals_prev > completed_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed goals</text>

                            </div>

                        </ReactCardFlip>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.7197 * width, justifyContent: 'space-between', height: 194, alignSelf: 'center', alignItems: 'flex-start', marginTop: 30 }}>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="horizontal" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_plans_prev} Open activities</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_plans_prev == incompleted_plans ? '#FAFF00' : incompleted_plans_prev > incompleted_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open activities</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_plans_prev} Open activities</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_plans_prev == incompleted_plans ? '#FAFF00' : incompleted_plans_prev > incompleted_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open activities</text>

                            </div>
                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="vertical" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_plans_prev} Completed activities</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: completed_plans_prev == completed_plans ? '#FAFF00' : completed_plans_prev > completed_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed activities</text>


                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.3467 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_plans_prev} Completed activities</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: completed_plans_prev == completed_plans ? '#FAFF00' : completed_plans_prev > completed_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed activities</text>


                            </div>

                        </ReactCardFlip>

                    </div>

                </div>


            </div>

            <wc-toast></wc-toast>

        </div> : <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'column', width: width, height: getHeight, position: 'absolute', top: 0, left: 0 }}>


            <div style={{ display: 'flex', flexDirection: 'column', width: width, height: getHeight, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 150, width: width, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <Popup
                        trigger={<div className="menu-item"><VscThreeBars color='white' size={35} style={{ marginLeft: 25 }} /></div>}
                        position="right top"
                        on="click"
                        closeOnDocumentClick={false}
                        mouseLeaveDelay={300}
                        mouseEnterDelay={30}
                        contentStyle={{ padding: '0px', border: 'none', marginRight: 50, width: 280, display: 'flex', borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                        arrow={true}
                        overlayStyle={{ marginLeft: -20 }}

                    >
                        <div style={{ backgroundColor: '#8455f6', display: 'flex', flexDirection: 'column', height: 580, width: 280, textAlign: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                                <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                            </div>

                            <div style={{ height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                                <div style={{ backgroundColor: '#FFFFFF', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 19 }}>{activities_dashboard_icon}</div>
                                    <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/TeamMembers')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{team_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/Payment')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/CompanyAccount')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{user_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                                </div>
                            </div>

                            <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />

                        </div>
                    </Popup>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>DASHBOARD</text>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: 0.97 * width, justifyContent: 'center', height: 'auto', backgroundColor: '#1F2327', alignSelf: 'center', alignItems: 'center', marginTop: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.9697 * width, justifyContent: 'space-between', height: 194, alignSelf: 'center', alignItems: 'center', marginTop: 30 }}>


                        <ReactCardFlip isFlipped={users != 0} flipDirection="horizontal" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{prev_users} Active Users</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: prev_users == users ? '#FAFF00' : prev_users > users ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>0</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Active Users</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{prev_users} Active Users</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: prev_users == users ? '#FAFF00' : prev_users > users ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{users}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Active Users</text>

                            </div>
                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="vertical" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{my_users.length==0?0:Number(users / my_users.length).toFixed(2)} % of users logged</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: '#FF4747', marginTop: 5, alignSelf: 'center' }}>0 %</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>of users logged</text>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{Number((users / my_users.length) * 100).toFixed(2)} % of users logged</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: '#FF4747', marginTop: 5, alignSelf: 'center' }}>{Number((users / my_users.length) * 100).toFixed(2)} %</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>of users logged</text>
                            </div>
                        </ReactCardFlip>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap', width: 0.96197 * width, justifyContent: 'space-evenly', height: 390, backgroundColor: '#292E33', marginTop: 30 }}>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.3052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.2868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile1} style={{ width: 25, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Excited</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Excited1 == emotions.Excited ? '#FAFF00' : emotions.Excited1 > emotions.Excited ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Excited}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Excited1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.3052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.2868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile} style={{ width: 25, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Happy</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Happy1 == emotions.Happy ? '#FAFF00' : emotions.Happy1 > emotions.Happy ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Happy}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Happy1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.3052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.2868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile} style={{ width:25, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Neutral</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Neutral1 == emotions.Neutral ? '#FAFF00' : emotions.Neutral1 > emotions.Neutral ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Neutral}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Neutral1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.3052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.2868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={cry1} style={{ width: 25, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Tired</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Tired1 == emotions.Tired ? '#FAFF00' : emotions.Tired1 > emotions.Tired ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Tired}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Tired1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.3052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.2868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={confused} style={{ width: 24, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Fed up</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Fed_Up1 == emotions.Fed_Up ? '#FAFF00' : emotions.Fed_Up1 > emotions.Fed_Up ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Fed_Up}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Fed_Up1}</text>


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.3052 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 160, backgroundColor: '#1F2327', borderRadius: 4 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', width: 0.2868 * width, justifyContent: 'space-evenly', alignSelf: 'center', height: 53, backgroundColor: '#8455F6', borderRadius: 4 }}>

                                <img src={smile1} style={{ width: 24, height: 24, alignSelf: 'center' }} />
                                <text style={{ textAlign: 'center', fontSize: 18, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', alignSelf: 'center', }}>Sad</text>


                            </div>
                            <text style={{ textAlign: 'center', fontSize: 24, fontWeight: '800', fontFamily: 'Roboto', color: emotions.Sad1 == emotions.Sad ? '#FAFF00' : emotions.Sad1 > emotions.Sad ? '#FF4747' : '#05FF00', alignSelf: 'center', }}>{emotions.Sad}</text>

                            <text style={{ textAlign: 'center', fontSize: 13, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', alignSelf: 'center', }}>Previous 7 days - {emotions.Sad1}</text>


                        </div>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.9697 * width, justifyContent: 'space-evenly', height: 194, alignSelf: 'center', alignItems: 'center', marginTop: 30 }}>


                        <ReactCardFlip isFlipped={users != 0} flipDirection="vertical" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_goals_prev} Open goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_goals_prev == incompleted_goals ? '#FAFF00' : incompleted_goals_prev > incompleted_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open goals</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_goals_prev} Open goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_goals_prev == incompleted_goals ? '#FAFF00' : incompleted_goals_prev > incompleted_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open goals</text>

                            </div>

                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="horizontal" containerStyle={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_goals_prev} Completed goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: completed_goals_prev == completed_goals ? '#FAFF00' : completed_goals_prev > completed_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed goals</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_goals_prev} Completed goals</text>
                                <text style={{ textAlign: 'center', fontSize: 60, fontWeight: '700', fontFamily: 'Roboto', color: completed_goals_prev == completed_goals ? '#FAFF00' : completed_goals_prev > completed_goals ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_goals}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed goals</text>

                            </div>

                        </ReactCardFlip>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', width: 0.9697 * width, justifyContent: 'space-evenly', height: 194, alignSelf: 'center', alignItems: 'center', marginTop: 15 }}>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="horizontal" containerStyle={{ display: 'flex', flexDirection: 'column', width:  0.4667 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_plans_prev} Open activities</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_plans_prev == incompleted_plans ? '#FAFF00' : incompleted_plans_prev > incompleted_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open activities</text>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width:  0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{incompleted_plans_prev} Open activities</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: incompleted_plans_prev == incompleted_plans ? '#FAFF00' : incompleted_plans_prev > incompleted_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{incompleted_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Open activities</text>

                            </div>
                        </ReactCardFlip>

                        <ReactCardFlip isFlipped={users != 0} flipDirection="vertical" containerStyle={{ display: 'flex', flexDirection: 'column', width:  0.4667 * width, justifyContent: 'center', height: 194, alignItems: 'center', }}>


                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_plans_prev} Completed activities</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: completed_plans_prev == completed_plans ? '#FAFF00' : completed_plans_prev > completed_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed activities</text>


                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: 0.4667 * width, justifyContent: 'flex-start', height: 194, backgroundColor: '#292E33' }}>

                                <text style={{ textAlign: 'center', fontSize: 12, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF80', marginTop: 8, alignSelf: 'flex-end', marginRight: 8 }}>Previous 7 days</text>
                                <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5, alignSelf: 'flex-end', marginRight: 8 }}>{completed_plans_prev} Completed activities</text>
                                <text style={{ textAlign: 'center', fontSize: 50, fontWeight: '700', fontFamily: 'Roboto', color: completed_plans_prev == completed_plans ? '#FAFF00' : completed_plans_prev > completed_plans ? '#FF4747' : '#05FF00', marginTop: 5, alignSelf: 'center' }}>{completed_plans}</text>
                                <text style={{ textAlign: 'center', fontSize: 28, fontWeight: '500', fontFamily: 'Roboto', color: '#8c52ff', marginTop: 5, alignSelf: 'center' }}>Completed activities</text>


                            </div>

                        </ReactCardFlip>

                    </div>

                </div>

            </div>

            <wc-toast></wc-toast>

        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId })(Dashboardcompany)
