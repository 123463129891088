import { combineReducers } from 'redux';
import { SET_ID, SET_PROJECT, ADD_PROJECT, ADD_URI, MAN_FAVOURITE, SWITCH_MODE, LOG_OUT, MAKE_TRIAL } from '../actions/index';

const initProduct = {
    projects: [],
    myid: null,
    mode: false,
    logout: false,
    trial: 0
}

function todoProduct(state = initProduct, action) {
    switch (action.type) {
        case ADD_URI:
            let nw = state.projects.map(artwork => {
                if (artwork.ID === action.payload.ID) {
                    // Create a *new* object with changes
                    return { ...artwork, uri: action.payload.uri };
                } else {
                    // No changes
                    return artwork;
                }
            })
            return { ...state, projects: [...nw] }
        case MAN_FAVOURITE:
            let nw1 = state.projects.map(artwork => {
                if (artwork.ID === action.payload.ID) {
                    // Create a *new* object with changes
                    return { ...artwork, favourite: action.payload.favourite };
                } else {
                    // No changes
                    return artwork;
                }
            })
            return { ...state, projects: [...nw1] }
        case ADD_PROJECT:
            state.projects.push(action.payload);
            return {
                ...state,
            }
        case SET_ID:
            return { ...state, myid: action.payload }
        case SWITCH_MODE:
            return { ...state, mode: !state.mode }
        case LOG_OUT:
            return { ...state, logout: action.payload }
        case SET_PROJECT:
            return { ...state, projects: [...action.payload] }
        case MAKE_TRIAL:
            return { ...state, trial: action.payload }
        default:
            return state;
    }
}
const ShopApp = combineReducers({
    _todoProduct: todoProduct
});

export default ShopApp;