import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import ReactiveButton from "reactive-button";
import { RiseLoader } from "react-spinners";

const override = `
display: block;
margin: 0 auto;
border-color: red;
alignSelf:center
`;

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);

    async function trying(e) {
        setLoading(true)

        if (!stripe || !elements) return;

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/`,
            },
            redirect: "if_required",
        });

        if (error) {
            console.log('Error', error)
            props.done(false)
        } else if (paymentIntent.status === "succeeded") {
            setMessage("Payment status: " + paymentIntent);
            console.log('Payemnt ', paymentIntent)
            props.done(true)
        } else {
            setMessage("Unexpected state");
            props.done(false)
        }

        setLoading(false)

    }


    const [loading, setLoading] = useState(false)


    return (
        <form id="payment-form">

            <PaymentElement id="payment-element" />

            {loading ? <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 80, height: 60, marginLeft: '40%' }}>
                <RiseLoader color={'#36D7B7'} isLoading={true}
                    css={override} size={20} />
            </div> : <div style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 20, height: 20 }}>

            </div>}

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 25 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Pay £{props.price} per month</text>}
                    successText="Done"
                    onClick={() => { trying() }}
                    style={{ backgroundColor: '#8455f6', width: 380, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 25 }}
                />
            </div>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}