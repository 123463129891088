
import '../App.css';
import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png'
import { useNavigate } from 'react-router-dom';
import { db, firestore } from '../config';
import moment from 'moment/moment';
import { FaUserFriends } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
//import 'react-toastify/dist/ReactToastify.css';
import ReactModal from 'react-modal';
import { toast } from 'wc-toast'
import { RxExternalLink } from "react-icons/rx";
import { active_activityplan_icon, active_goal_icon, active_journal_icon, active_vision_icon, active_week_plan_icon, activities_dashboard_icon, inactive_activityplan_icon, inactive_dashboard_icon, inactive_goal_icon, inactive_habit_icon, inactive_journal_icon, inactive_video_icon, inactive_vision_icon, inactive_week_plan_icon, inactive_win_icon, logout_icon, pay_inactive, team_inactive, user_active } from '../assets/Icon';
import ReactiveButton from 'reactive-button';
import { RxCross1 } from "react-icons/rx";
import { connect } from 'react-redux';
import { SetId } from '../screens/redux/actions';
import { decrypt, decryptPass, encryptPass } from '../Crypto';
import { Database } from 'firebase/database';
import ToggleButton from 'react-toggle-button'

import { BiSolidImageAdd } from "react-icons/bi";
import { MdOutlineChevronRight } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { VscThreeBars } from 'react-icons/vsc';
import Popup from 'reactjs-popup';
import Modal from 'react-responsive-modal';
import { IoMdEyeOff } from 'react-icons/io';
import { IoEye } from 'react-icons/io5';
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

const inactive_color = '#cbb6fe'

const out_link = <RxExternalLink size={24} color='black' />

const partnerdb = db.ref('partnerdb');
const customerdb = db.ref('customerdb');

const candidate_db = firestore.collection('candidate_db')

const recruiter_db = db.ref('recruiter_db');

const coachdb = db.ref('coachdb')


const recruiter_active = <FaUserTie color='white' size={22} />
const recruiter_active_non = <FaUserTie color='#B9B9B9' size={22} />


function CompanyAccount({ items, SetId }) {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(Math.max(window.innerHeight, 1100));

    useEffect(() => {
        const windowSizeHandler = () => {
            setWidth(window.innerWidth)
            setHeight(Math.max(window.innerHeight, 1100))
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);


    let curr = new Date().getTime();

    const navigate = useNavigate();

    const [touched1, setTouched1] = useState(false);
    const [password_pop_up, setPasswordPopUp] = useState(true);

    const [my_open_goals, setMyOpenGoals] = useState([])



    const [emotion, setEmotion] = useState(false)

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const isMobile = width <= 768;

    const getHeight = Math.max(height, 1900)

    const [edit_pop, setEditPop] = useState(false)
    const [secure, setSecure] = useState(false)
    const [pass, setPass] = useState('')

    console.log('Id ', items.myid)

    async function make_edit() {
        await coachdb.child(String(items.myid.ID)).child('pass').set(encryptPass(pass))
        toast('Password has been updated')
        setEditPop(false)
    }


    return (
        !isMobile ? <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'row', width: width, height: height, position: 'absolute', top: 0, left: 0 }}>
            <div style={{ backgroundColor: '#8455f6', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', height: height, width: 286, textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                    <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                </div>

                <div onClick={() => navigate('/Dashboardcompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                    <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                    </div>
                </div>

                <div onClick={() => navigate('/TeamMembers')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{team_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                    </div>
                </div>

                <div onClick={() => navigate('/Payment')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                    </div>
                </div>

                <div onClick={() => navigate('/CompanyAccount')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 19 }}>{user_active}</div>
                        <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                    </div>
                </div>

                <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                    <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                        <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                        <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                    </div>
                </div>

                <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 286, width: width - 286, position: 'absolute', top: 0, height: height, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 100, width: width - 286, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <text></text>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>ACCOUNT</text>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: 0.4 * width, justifyContent: 'center', height: 874, backgroundColor: '#292E33', alignSelf: 'center', marginTop: 20 }}>


                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.2980 * width, justifyContent: 'flex-start', height: 784, alignSelf: 'center' }}>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.0881 * width, justifyContent: 'center', height: 134, alignSelf: 'center', backgroundColor: '#1F2327', alignItems: 'center', borderRadius: 8 }}>


                            <BiSolidImageAdd onClick={() => ('/')} style={{ width: 38, height: 38, alignSelf: 'center', color: '#575757' }} />
                            <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#575757', marginTop: 5, alignSelf: 'center' }}>Add Profile image</text>


                        </div>


                        <div style={{ display: 'flex', width: 0.2980 * width, height: 20, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', marginTop: 40 }}>
                            <div style={{ display: 'flex', width: 0.1400 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>First name</text>

                            </div>
                            <div style={{ display: 'flex', width: 0.1400 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>Last name</text>

                            </div>
                        </div>


                        <div style={{ display: 'flex', width: 0.2980 * width, height: 48, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', marginTop: 5 }}>
                            <input placeholder='Joson' value={decrypt(items.myid.first)} style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'white', fontWeight: '400', fontSize: 18, backgroundColor: '#1F2327', width: 0.1350 * width, height: 43, outline: 'none', borderStyle: 'none', borderRadius: 4, paddingLeft: 10 }} />

                            <input placeholder='Statham' value={decrypt(items.myid.second)} style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'white', fontWeight: '400', fontSize: 18, backgroundColor: '#1F2327', width: 0.1350 * width, height: 43, outline: 'none', borderStyle: 'none', borderRadius: 4, paddingLeft: 10 }} />

                        </div>

                        <div style={{ display: 'flex', width: 0.2980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>Email</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>

                            <input value={decrypt(items.myid.email)} placeholder='Email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'white', fontWeight: '400', fontSize: 18, backgroundColor: '#1F2327', width: 0.2980 * width, height: 43, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 4 }} />


                        </div>

                        <div onClick={() => { setEditPop(true); setPass(decryptPass(items.myid.pass)) }} style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2500 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Update Password</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.480 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2500 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>About Us</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.480 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>
                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2500 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Payment </text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.480 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>
                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2500 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Report an Issue</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.480 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>
                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.2500 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Privacy Policy</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.480 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#FF474733', height: 43, width: 0.2980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#FF474733', height: 43, width: 0.2500 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Delete Account</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#FF474733', height: 43, width: 0.480 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                <RiDeleteBin5Fill onClick={() => ('/')} style={{ height: 22, width: 20, color: '#FF4747', marginRight: 8 }} />
                            </div>


                        </div>

                    </div>


                </div>

            </div>

            <Modal
                open={edit_pop != false}
                onClose={() => setEditPop(false)}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 400, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontFamily: 'Montserrat', fontSize: 20 }}>Update Password</text>
                    </div>

                    <div style={{ width: 384, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: 'white', borderRadius: 6, justifyContent: 'space-between', marginTop: 4, borderStyle: 'solid', borderWidth: 1, borderColor: 'black', }}>
                        <input placeholder='Password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ borderStyle: 'none', paddingLeft: 4, fontFamily: 'Montserrat', outline: 'none', height: 42, width: 295, outline: 'none', marginLeft: 4 }} />
                        <div style={{ width: 50 }}>
                            {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} />}
                        </div>
                    </div>

                    <div onClick={() => make_edit()} style={{ cursor: 'pointer', width: 175, padding: 10, backgroundColor: '#8c52ff', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <text style={{ color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>
                    </div>
                </div>
            </Modal>

            <wc-toast></wc-toast>

        </div> : <div style={{ backgroundColor: '#f3f9eb', display: 'flex', flexDirection: 'row', width: width, height: getHeight, position: 'absolute', top: 0, left: 0 }}>

            <div style={{ display: 'flex', flexDirection: 'column', width: width, height: getHeight, backgroundColor: '#1f2327' }}>

                <div style={{ display: 'flex', height: 150, width: width, justifyContent: 'left', alignItems: 'center', backgroundColor: '#292e33', boxShadow: '1px 1px 1px 1px #292E33' }}>
                    <Popup
                        trigger={<div className="menu-item"><VscThreeBars color='white' size={35} style={{ marginLeft: 25 }} /></div>}
                        position="right top"
                        on="click"
                        closeOnDocumentClick={false}
                        mouseLeaveDelay={300}
                        mouseEnterDelay={30}
                        contentStyle={{ padding: '0px', border: 'none', marginRight: 50, width: 280, display: 'flex', borderStyle: 'solid', borderWidth: 1, borderColor: 'white' }}
                        arrow={true}
                        overlayStyle={{ marginLeft: -20 }}

                    >
                        <div style={{ backgroundColor: '#8455f6', display: 'flex', flexDirection: 'column', height: 580, width: 280, textAlign: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: "#8455f6", width: 276, height: 100, justifyContent: 'center', alignItems: 'center' }}>
                                <text style={{ color: 'white', marginTop: 30, fontSize: 15, fontFamily: 'Roboto' }}>{decrypt(items.myid.first)} {decrypt(items.myid.second)}</text>
                            </div>

                            <div onClick={() => navigate('/Dashboardcompany')} style={{ cursor: 'pointer', height: 48, marginTop: 20, display: 'flex', justifyContent: 'center', alignContent: 'center', width: 286, padding: 10, alignSelf: 'center', alignItems: 'center' }}>
                                <div style={{ width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 19 }}>{inactive_dashboard_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Dashboard</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/TeamMembers')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{team_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Team members</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/Payment')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{pay_inactive}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Payments</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/CompanyAccount')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ backgroundColor: '#FFFFFF', flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 19 }}>{user_active}</div>
                                    <text style={{ color: '#8455F6', marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Account</text>
                                </div>
                            </div>

                            <div onClick={() => navigate('/company_login')} style={{ height: 48, cursor: 'pointer', marginTop: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center', width: 286, padding: 10, backgroundColor: 'transparent', alignSelf: 'center' }}>
                                <div style={{ flexDirection: 'row', width: 210, flexDirection: 'row', display: 'flex', padding: 10, borderRadius: 6, alignItems: 'center' }}>
                                    <div style={{ color: 'white', fontSize: 23 }}>{logout_icon}</div>
                                    <text style={{ color: inactive_color, marginLeft: 30, fontSize: 15, fontFamily: 'Roboto' }}>Log Out</text>
                                </div>
                            </div>

                            <img src={logo} style={{ width: 80, height: 80, alignSelf: 'center', marginTop: 20 }} />


                        </div>
                    </Popup>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'content' }}>
                        <text style={{ marginLeft: 40, fontSize: 40, fontWeight: '600', letterSpacing: 1.01, color: '#8c52ff', textAlign: 'left', fontFamily: 'Barlow Condensed' }}>ACCOUNT</text>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: 0.96 * width, justifyContent: 'center', height: 874, backgroundColor: '#292E33', alignSelf: 'center', marginTop: 20, alignItems: 'center' }}>


                    <div style={{ display: 'flex', flexDirection: 'column', width: 0.8980 * width, justifyContent: 'flex-start', height: 784, alignSelf: 'center' }}>


                        <div style={{ display: 'flex', flexDirection: 'column', width: 0.5881 * width, justifyContent: 'center', height: 134, alignSelf: 'center', backgroundColor: '#1F2327', alignItems: 'center', borderRadius: 8 }}>


                            <BiSolidImageAdd onClick={() => ('/')} style={{ width: 38, height: 38, alignSelf: 'center', color: '#575757' }} />
                            <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '500', fontFamily: 'Roboto', color: '#575757', marginTop: 5, alignSelf: 'center' }}>Add Profile image</text>


                        </div>


                        <div style={{ display: 'flex', width: 0.8980 * width, height: 20, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', marginTop: 40 }}>
                            <div style={{ display: 'flex', width: 0.8900 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>First name</text>

                            </div>
                        </div>


                        <div style={{ display: 'flex', width: 0.8980 * width, height: 48, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', marginTop: 5 }}>
                            <input disabled placeholder='Joson' value={decrypt(items.myid.first)} style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'white', fontWeight: '400', fontSize: 18, backgroundColor: '#1F2327', width: 0.8950 * width, height: 43, outline: 'none', borderStyle: 'none', borderRadius: 4, paddingLeft: 10 }} />


                        </div>

                        <div style={{ display: 'flex', width: 0.8980 * width, height: 20, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                            <div style={{ display: 'flex', width: 0.4300 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>Last name</text>

                            </div>
                        </div>


                        <div style={{ display: 'flex', width: 0.8980 * width, height: 48, flexDirection: 'row', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between', marginTop: 5 }}>

                            <input disabled placeholder='Statham' value={decrypt(items.myid.second)} style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'white', fontWeight: '400', fontSize: 18, backgroundColor: '#1F2327', width: 0.8950 * width, height: 43, outline: 'none', borderStyle: 'none', borderRadius: 4, paddingLeft: 10 }} />

                        </div>

                        <div style={{ display: 'flex', width: 0.8980 * width, height: 20, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', marginTop: 15 }}>
                            <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginTop: 5 }}>Email</text>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.8980 * width, borderRadius: 4, marginTop: 5, alignItems: 'center', alignSelf: 'center', }}>

                            <input value={decrypt(items.myid.email)} placeholder='Email' style={{ alignSelf: 'center', fontFamily: 'Roboto', color: 'white', fontWeight: '400', fontSize: 18, backgroundColor: '#1F2327', width: 0.8980 * width, height: 45, paddingLeft: 10, outline: 'none', borderStyle: 'none', borderRadius: 4 }} />


                        </div>

                        <div onClick={() => { setEditPop(true); setPass(decryptPass(items.myid.pass)) }} style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.8980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.4300 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Update Password</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.430 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>

                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.8980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.4300 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>About Us</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 45, width: 0.430 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>
                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.8980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.4300 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Payment </text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.430 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>
                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.8980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.4300 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Report an Issue</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.430 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>
                        <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.8980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.4300 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Privacy Policy</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#1F2327', height: 43, width: 0.430 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end', marginRight: 5 }}>
                                <MdOutlineChevronRight onClick={() => ('/')} style={{ height: 24, width: 24, color: '#FFFFFF' }} />
                            </div>


                        </div>

                        <div style={{ display: 'flex', backgroundColor: '#FF474733', height: 43, width: 0.5980 * width, borderRadius: 4, marginTop: 15, alignItems: 'center', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', backgroundColor: '#FF474733', height: 43, width: 0.4300 * width, borderTopLeftRadius: 4, borderBottomLeftRadius: 4, alignItems: 'center', alignSelf: 'center' }}>
                                <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#FFFFFF', marginLeft: 10 }}>Delete Account</text>
                            </div>

                            <div style={{ display: 'flex', backgroundColor: '#FF474733', height: 43, width: 0.430 * width, borderTopRightRadius: 4, borderBottomRightRadius: 4, alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-end' }}>
                                <RiDeleteBin5Fill onClick={() => ('/')} style={{ height: 22, width: 20, color: '#FF4747', marginRight: 8 }} />
                            </div>


                        </div>

                    </div>


                </div>

            </div>

            <wc-toast></wc-toast>

            <Modal
                open={edit_pop != false}
                onClose={() => setEditPop(false)}
                center
                styles={{ width: 'auto', height: 'auto', backgroundColor: 'white', padding: 10 }}
            >
                <div style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center', width: 300, alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
                        <text style={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: '500', fontFamily: 'Montserrat', fontSize: 20 }}>Update Password</text>
                    </div>

                    <div style={{ width: 284, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: 'white', borderRadius: 6, justifyContent: 'space-between', marginTop: 4, borderStyle: 'solid', borderWidth: 1, borderColor: 'black', }}>
                        <input placeholder='Password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ borderStyle: 'none', paddingLeft: 4, fontFamily: 'Montserrat', outline: 'none', height: 42, width: 295, outline: 'none', marginLeft: 4 }} />
                        <div style={{ width: 50 }}>
                            {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='black' style={{ marginRight: 2 }} />}
                        </div>
                    </div>

                    <div onClick={() => make_edit()} style={{ cursor: 'pointer', width: 175, padding: 10, backgroundColor: '#8c52ff', borderRadius: 10, alignItems: 'center', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                        <text style={{ color: 'white', fontFamily: 'Montserrat', textAlign: 'center' }}>Update</text>
                    </div>
                </div>
            </Modal>

        </div>
    );
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId })(CompanyAccount)
