
import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/left.png'
import logos from '../assets/logo.png'

import ReactiveButton from 'reactive-button';
import Select, { components } from 'react-select';
import '../App.css'
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDropUpLine } from "react-icons/ri";
import { MdOutlinePassword } from 'react-icons/md'
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { db } from '../config';
import { encrypt, encryptPass, encryptPhone } from '../Crypto';
import { connect } from 'react-redux';
import { SetId, SetProject } from './redux/actions';
import { toast } from 'wc-toast';
import { useLottie } from 'lottie-react';
import waiting from '../assets/Waiting.json'
import ReactModal from 'react-modal';
import AnimateHeight from 'react-animate-height';

const userdb = db.ref('userdb')
const coachdb = db.ref('coachdb')

const search_icon = <RiArrowDropDownLine color='#0b6d0f' size={24} />
const search_icon_another = <RiArrowDropUpLine color='#0b6d0f' size={24} />

const arr = []

function SignUpCompany({ SetId, SetProject }) {

    const navigate = useNavigate()

    let path = window.location.href;

    function getMail() {
        let str = String(path).substring(String(path).indexOf('?') + 1, String(path).length)
        return str;
    }

    const DropdownIndicator = props => {
        console.log('Props ', props)
        if (props.isFocused) return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon}
                    </div>
                </components.DropdownIndicator>
            )
        );
        else return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div style={{ height: 28, width: 30, borderRadius: 4, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        {search_icon_another}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    console.log('Width ', windowWidth)

    useEffect(() => {
        const windowSizeHandler = () => {
            setWindowWidth(window.innerWidth)
            setWindowHeight(window.innerHeight)
        };
        window.addEventListener("resize", windowSizeHandler);

        return () => {
            window.removeEventListener("resize", windowSizeHandler);
        };
    }, []);


    const [first, setFirst] = useState('')
    const [second, setSecond] = useState('')
    const [company_name, setCompanyName] = useState('')

    const [pass, setPass] = useState('')
    const [secure, setSecure] = useState(true)

    const [loading, setLoading] = useState(false)

    const [mail, setMail] = useState('')

    const [autofill, setAutoFill] = useState(false)

    useEffect(() => {
        if (path.indexOf('?') > 0) {
            let mymail = getMail();
            setMail(mymail);
            setAsUser(true)
            setDeletePopUp(false)
            setAutoFill(true)
        }
        else {
            setDeletePopUp(true)
        }
    }, [])

    function hasLowerCase(str) {
        return str.toUpperCase() != str;
    }

    function hasUpperCase(str) {
        return str.toLowerCase() != str;
    }

    function ispass(pass) {
        let ret = 0;
        if (hasLowerCase(pass)) ret++;
        if (hasUpperCase(pass)) ret++;
        if (pass.length >= 6) ret++;
        if (pass.includes(0) || pass.includes(1) || pass.includes(2) || pass.includes(3) || pass.includes(4) || pass.includes(5) || pass.includes(6) || pass.includes(7) || pass.includes(8) || pass.includes(9)) ret++;

        return ret == 4

    }


    async function signup_coach() {
        if (first != '' && second != '' && pass != '' && ispass(pass) && company_name != '') {
            toast('Please wait a while')
            let check = await coachdb.orderByChild('email').equalTo(encrypt(mail)).once('value')
            setLoading(true)
            if (check.val() == null) {
                let time = new Date().getTime()
                let obj = {
                    first: encrypt(first.trim()),
                    second: encrypt(second.trim()),

                    email: encrypt(mail),
                    pass: encryptPass(pass),
                    ID: String(time),
                    company_name: encrypt(company_name)
                }
                await coachdb.child(String(time)).set(obj)
                setLoading(false)
                SetId(obj)
                toast('Your account has been created')
                navigate('/Packages')
            }
            else {
                toast('This email already exist')
                setLoading(false)
            }

        }
        else {
            toast('Check your all input fields')
        }
    }



    const options = {
        animationData: waiting,
        loop: true,
        width: 800,
        height: 800
    };

    const { View } = useLottie(options);

    const [delete_pop_up, setDeletePopUp] = useState(false)
    const [as_user, setAsUser] = useState(false)

    const [he, setHe] = useState(0)

    useEffect(() => {
        if (isMobile) setHe(700)
        else setHe(windowHeight)
    }, [windowHeight])

    const isMobile = windowWidth <= 768;

    const getHeight = Math.max(windowHeight, 1150)

    const mobileRef = useRef()

    useEffect(() => {
        mobileRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (!isMobile ? <div style={{ display: 'flex', backgroundColor: '#1f2327', height: windowHeight, width: windowWidth, flexDirection: 'row' }}>

        <wc-toast></wc-toast>

        <div style={{ width: 0.45 * windowWidth, height: windowHeight, display: 'block', justifyContent: 'center', backgroundColor: '#1f2327' }}>
            <img src={logo} style={{ width: 0.45 * windowWidth, height: windowHeight }} />
            <img src={logos} style={{ width: 330, height: 330, position: 'absolute', top: (0.50 * windowHeight - 165), left: (0.22 * windowWidth - 165) }} />
        </div>

        <AnimateHeight duration={900} height={he} style={{ width: 0.55 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: windowHeight, display: 'flex' }}>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'center', fontSize: 38, fontWeight: '700', fontFamily: 'Roboto', color: '#8455f6' }}>Welcome Back</text>
                    <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '700', fontFamily: 'Roboto', color: '#A0AEC0' }}>Create your account</text>
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Company name</text>
                    <input placeholder=' Your company name' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 380, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={company_name} onChange={(e) => setCompanyName(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>First name</text>
                    <input placeholder=' Your first name' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 380, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={first} onChange={(e) => setFirst(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Last name</text>
                    <input placeholder=' Your last name' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 380, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={second} onChange={(e) => setSecond(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 380, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Email</text>
                    <input disabled={autofill} placeholder=' Your email address' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 380, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.55 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', marginLeft: 4 }}>
                <text style={{ width: 391, textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3', alignSelf: 'center' }}>Password</text>
                <div style={{ width: 391, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: '#292e33', borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', justifyContent: 'space-between', marginTop: 4 }}>
                    <input placeholder='Your password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ paddingLeft: 5, fontFamily: 'Roboto', color: '#A0AEC0', fontSize: 14, fontWeight: '400', backgroundColor: '#292e33', borderStyle: 'none', outline: 'none', height: 45, width: 354, marginLeft: 6, outline: 'none' }} />
                    <div style={{ width: 54 }}>
                        {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} />}
                    </div>
                </div>
                {!ispass(pass) ? <text style={{ fontFamily: 'Roboto', width: 387, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}>Password should contain lowecase , uppercase , numbers and at least 6 characters in length</text> : <text style={{ fontFamily: 'Montserrat', width: 400, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}></text>}
            </div>


            {!as_user ? <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 50 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Signup as company</text>}
                    successText="Done"
                    onClick={() => { signup_coach() }}
                    style={{ backgroundColor: '#8455f6', width: 380, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 25, marginBottom: 20 }}
                />
            </div> : null}
            <text style={{ fontFamily: 'Roboto', marginTop: 50, textAlign: 'center', fontSize: 14, fontWeight: '400', color: '#a4b2c3' }}>Have an account ? <text onClick={() => navigate('/company_login')} style={{ fontFamily: 'Roboto', fontSize: 17, fontWeight: '700', marginLeft: 4, cursor: 'pointer', color: '#8455f6' }}>Sign In</text></text>

        </AnimateHeight>

        {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
            {View}
        </div> : null}

    </div> : <div style={{ display: 'flex', backgroundColor: '#1f2327', height: getHeight, width: windowWidth, flexDirection: 'column' }}>

        <wc-toast></wc-toast>

        <div style={{ width: windowWidth, height: getHeight / 2.5, display: 'flex', justifyContent: 'center', backgroundColor: '#1f2327' }}>
            <img src={logo} style={{ width: windowWidth, height: getHeight / 2.5 }} />
            <img ref={mobileRef} src={logos} style={{ width: 230, height: 230, position: 'absolute', top: ((getHeight / 2.5) / 2) - 115, }} />
        </div>

        <AnimateHeight duration={900} height={he} style={{ zIndex: 4000, width: 0.95 * windowWidth, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: getHeight - (getHeight / 2.5), display: 'flex', alignSelf: 'center', marginTop: 10 }}>


            <div style={{ zIndex: 4000, width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'center', fontSize: 38, fontWeight: '700', fontFamily: 'Roboto', color: '#8455f6' }}>Welcome Back</text>
                    <text style={{ textAlign: 'center', fontSize: 14, fontWeight: '700', fontFamily: 'Roboto', color: '#A0AEC0' }}>Create your account</text>
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Company name</text>
                    <input placeholder=' Your company name' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 300, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={company_name} onChange={(e) => setCompanyName(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>First name</text>
                    <input placeholder=' Your first name' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 300, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={first} onChange={(e) => setFirst(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Last name</text>
                    <input placeholder=' Your last name' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 300, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={second} onChange={(e) => setSecond(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <text style={{ textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3' }}>Email</text>
                    <input disabled={autofill} placeholder=' Your email address' style={{ paddingLeft: 6, fontFamily: 'Roboto', color: '#A0AEC0', fontWeight: '400', fontSize: 14, backgroundColor: '#292e33', marginTop: 6, width: 300, height: 48, borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', outline: 'none' }} value={mail} onChange={(e) => setMail(e.currentTarget.value)} />
                </div>
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', marginTop: 20, display: 'flex', flexDirection: 'column', marginLeft: 4 }}>
                <text style={{ width: 300, textAlign: 'left', fontSize: 14, fontWeight: '400', fontFamily: 'Roboto', color: '#a4b2c3', alignSelf: 'center' }}>Password</text>
                <div style={{ width: 307, display: 'flex', flexDirection: 'row', alignItems: 'center', height: 48, alignSelf: 'center', backgroundColor: '#292e33', borderRadius: 10, borderStyle: 'solid', borderWidth: 0, borderColor: '#41A4C3', justifyContent: 'space-between', marginTop: 4 }}>
                    <input placeholder='Your password' value={pass} type={secure != '' ? 'password' : 'text'} onChange={(e) => setPass(e.currentTarget.value)} style={{ paddingLeft: 5, fontFamily: 'Roboto', color: '#A0AEC0', fontSize: 14, fontWeight: '400', backgroundColor: '#292e33', borderStyle: 'none', outline: 'none', height: 45, width: 300, marginLeft: 6, outline: 'none' }} />
                    <div style={{ width: 54 }}>
                        {secure ? <IoMdEyeOff onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} /> : <IoEye onClick={() => setSecure(!secure)} size={20} color='#A0AEC0' style={{ marginRight: 2 }} />}
                    </div>
                </div>
                {!ispass(pass) ? <text style={{ fontFamily: 'Roboto', width: 300, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}>Password should contain lowecase , uppercase , numbers and at least 6 characters in length</text> : <text style={{ fontFamily: 'Montserrat', width: 300, alignSelf: 'center', textAlign: 'left', marginBottom: 3, marginTop: 4, fontSize: 9, fontWeight: '500', display: 'flex', color: 'white' }}></text>}
            </div>

            <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginRight: 0 }}>
                <ReactiveButton
                    idleText={<text style={{ fontWeight: '500', fontSize: 23, color: 'white', fontFamily: 'Roboto', textAlign: 'center' }}>Signup</text>}
                    successText="Done"
                    onClick={() => { signup_coach() }}
                    style={{ backgroundColor: '#8455f6', width: 300, borderRadius: 10, alignSelf: 'center', height: 54, display: 'flex', justifyContent: 'center', marginTop: 20 }}
                />
            </div>

            <div style={{ width: 0.95 * windowWidth, alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: 300, alignSelf: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <text style={{ fontFamily: 'Roboto', marginTop: 50, textAlign: 'center', fontSize: 14, fontWeight: '400', color: '#a4b2c3' }}>Have an account ? <text onClick={() => navigate('/company_login')} style={{ fontFamily: 'Roboto', fontSize: 17, fontWeight: '700', marginLeft: 4, cursor: 'pointer', color: '#8455f6' }}>Sign In</text></text>
                </div>
            </div>



        </AnimateHeight>

        {loading ? <div style={{ position: 'absolute', zIndex: 1000, top: '40%', alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', left: (windowWidth - (0.45 * windowWidth)), borderRadius: 10 }}>
            {View}
        </div> : null}


    </div>)
}

const mapStateToProps = state => {
    //  console.log(state)
    return {
        items: state._todoProduct
    }
}

export default connect(mapStateToProps, { SetId, SetProject })(SignUpCompany)